import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ApiListPage from '../components/apicheck/page/ApiListPage';
import ProjectListPage from '../components/project/pages/ProjectListPage';
import LoginPage from '../components/sign/page/LoginPage';
import ProjectInfoPage from '../components/project/pages/ProjectInfoPage';
import RegisterApiPage from '../components/project/pages/RegisterApiPage';
import EditApiPage from '../components/project/pages/EditApiPage';
import FTPListPage from '../components/ftp/page/FTPListPage';
import FTPEditPage from '../components/ftp/page/FTPEditPage';
import FTPUploadPage from '../components/ftp/page/FTPUploadPage';
import CommonCodePage from '../components/code/page/CommonCodePage';
import ProjectUploadPage from '../components/project/pages/ProjectUploadPage';
import UploadCommonCodePage from '../components/code/page/UploadCommonCodePage';
import HomePage from '../components/home/page/HomePage';
import BoardListPage from '../components/board/page/BoardListPage';
import BoardUploadPage from '../components/board/page/BoardUploadPage';

export default function Router() {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/project" element={<ProjectListPage />} />
                <Route path='/project/upload' element={<ProjectUploadPage />} />
                <Route path="/project/:project_id" element={<ProjectInfoPage />} />
                <Route path="/project/:project_id/apis" element={<ApiListPage />} />
                <Route path="/project/:project_id/apis/upload" element={<RegisterApiPage />} />
                <Route path='/project/:project_id/apis/:api_id' element={<EditApiPage />} />
                <Route path='/ftp' element={<FTPListPage />} />
                <Route path='/ftp/upload' element={<FTPUploadPage />} />
                <Route path='/ftp/:ftp_id' element={<FTPEditPage />} />
                <Route path='/code' element={<CommonCodePage />} />
                <Route path='/code/upload' element={<UploadCommonCodePage />} />
                <Route path='/board' element={<BoardListPage />} />
                <Route path='/board/upload' element={<BoardUploadPage />} />
            </Routes>
        </BrowserRouter>
    )
}