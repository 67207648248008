import { Button } from '@mui/material';

interface BasicButtonProps {
    children: any;
    onPress?: any;
    variant?: 'text' | 'contained' | 'outlined';
    color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | undefined;
    size?: 'small' | 'medium' | 'large';
    [x: string]: any;
    type?: 'submit' | 'button';
}

export default function BasicButton({ children, onPress = () => { }, variant = 'contained', color = 'primary', size = 'large',type = 'button', ...rest }: BasicButtonProps) {
    return (
        <>
            <Button
                variant={variant}
                color={color}
                onClick={onPress}
                size={size}
                style={{ textTransform: 'none' }}
                type={type}
                {...rest}
            >
                {children}
            </Button>
        </>
    )
}