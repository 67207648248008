import styled from "@emotion/styled"
import BasicText from "../../basic/BasicText"
import BasicButton from "../../basic/BasicButton"
import { useState } from "react"
import TitleWithTextFieldItem from "../../common/item/TitleWithTextFieldItem"
import TitleWithSelectItem from "../../common/item/TitleWithSelectItem"
import { apiAddCommonCode } from "../../../api/commonCode/commonCode_api"
import { userUserAuthPayload } from "../../../utils/custom-hooks/common/userUserAuthPayload"

export default function CommonCodeUploadPart() {
    const authPayload = userUserAuthPayload();

    const [codeData, setCodeData] = useState<any>({
        code_type: '1',
        code_common: '1',
    });

    function setCodeDataHandler(key: string, value: any) {
        setCodeData((prev: any) => {
            const newCodeData = { ...prev };

            newCodeData[key] = value;

            return newCodeData;
        })
    }

    function uploadCodeHandler() {
        apiAddCommonCode(codeData, authPayload).then((res: any) => {
            if (res.result) {
                alert('Code uploaded successfully!');
            }
        })
    }

    return (
        <PartWrap>
            <PartHeaderWrap>
                <BasicText fontSize={30} fontWeight={700}>NEW CODE</BasicText>
            </PartHeaderWrap>
            <UploadInputWrap>
                <TitleWithTextFieldItem value={codeData.code_name} onChange={(value: string) => { setCodeDataHandler('code_name', value) }} title="CODE NAME" showTextLength={false} style={{ marginBottom: '15px' }} />
                <TitleWithTextFieldItem value={codeData.code_number} onChange={(value: string) => { setCodeDataHandler('code_number', value) }} title="CODE NUMBER" showTextLength={false} style={{ marginBottom: '15px' }} />
                <TitleWithSelectItem value={codeData.code_type} onChange={(value: string) => { setCodeDataHandler('code_type', value) }} title="CODE TYPE" style={{ marginBottom: '15px' }} selectItems={[{ value: '1', title: 'SUCCESS' }, { value: '2', title: 'ERROR' }]} />
                <TitleWithTextFieldItem value={codeData.code_description} onChange={(value: string) => { setCodeDataHandler('code_description', value) }} title="CODE DESCRIPTION" showTextLength={false} style={{ marginBottom: '15px' }} />
                <TitleWithTextFieldItem value={codeData.code_error_text_en} onChange={(value: string) => { setCodeDataHandler('code_error_text_en', value) }} title="CODE ERROR DESCRIPTION(ENGLISH)" showTextLength={false} style={{ marginBottom: '15px' }} />
                <TitleWithTextFieldItem value={codeData.code_error_text_kr} onChange={(value: string) => { setCodeDataHandler('code_error_text_kr', value) }} title="CODE ERROR DESCRIPTION(OTHER LANGUAGES)" showTextLength={false} style={{ marginBottom: '15px' }} />
                <TitleWithSelectItem value={codeData.code_common} onChange={(value: string) => { setCodeDataHandler('code_common', value) }} title="CODE COMMON" selectItems={[{ value: '1', title: 'COMMON' }, { value: '0', title: 'UNCOMMON' }]} />
            </UploadInputWrap>
            <PartFooterWrap>
                <BasicButton onPress={uploadCodeHandler}>
                    <BasicText>UPLOAD</BasicText>
                </BasicButton>
            </PartFooterWrap>
        </PartWrap>
    )
}

const PartWrap = styled.div`
padding: 30px;
`

const PartHeaderWrap = styled.div`
margin-bottom: 30px;
`

const UploadInputWrap = styled.div``

const PartFooterWrap = styled.div`
margin-top: 30px;
`