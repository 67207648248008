import { useState } from 'react';
import styled from 'styled-components';
import BasicText from '../../basic/BasicText';
import { useNavigate } from 'react-router';
import BasicButton from '../../basic/BasicButton';
import { projectTypeConfig } from '../../../config/projectTypeConfig';
import { Row } from '../../../style/basic/commonStyle';

export default function ProjectListItem({ data, onDelete }: any) {
    const navigate = useNavigate();
    
    const [showPlatformInfo, setShowPlatformInfo] = useState(false);

    const platformInfoHeight = 120;

    return (
        // 마우스 호버할 경우 해당 아이템의 높이길이px이 늘어나면서 자세한 정보를 렌더
        <ItemWrap>
            <BasicInfoWrap>
                <InfoWrap onClick={()=>setShowPlatformInfo(!showPlatformInfo)}>
                    <ProjectIconWrap>
                        <img style={{ maxWidth: '100%', maxHeight: '100%' }} src={data.project_icon} alt={'icon'} />
                    </ProjectIconWrap>
                    <ProjectIntroWrap>
                        <BasicText fontSize={22} fontWeight={700}>{data.project_name}</BasicText>
                        <BasicText fontSize={14}>{data.project_description}</BasicText>
                        <BasicText fontSize={14}>{projectTypeConfig(data.project_type)}</BasicText>
                    </ProjectIntroWrap>
                </InfoWrap>
                <ButtonWrap>
                    <InfoButtonWrap>
                        <BasicButton onPress={() => { navigate(`/project/${data.project_id}`); }}>
                            <BasicText fontSize={14}>DETAIL</BasicText>
                        </BasicButton>
                        <BasicButton onPress={() => { navigate(`/project/${data.project_id}/apis`) }} variant="text">
                            <BasicText fontSize={14}>VIEW APIS</BasicText>
                        </BasicButton>
                    </InfoButtonWrap>
                    <DeleteButtonWrap>
                        <BasicButton sx={{ height: '100%' }} color='error' onPress={() => { onDelete(data.project_id) }} variant="text">
                            <BasicText fontSize={14}>DELETE</BasicText>
                        </BasicButton>
                    </DeleteButtonWrap>
                </ButtonWrap>
            </BasicInfoWrap>
            <PlatformTable showPlatformInfo={showPlatformInfo}>
                {data.project_platform.map((item: any) => (
                    <PlatformTableWrap key={`${Math.random()}`} height={platformInfoHeight}>
                        <TableHeaderWrap>
                            <HeaderItemWrap style={{ width: '20%' }}>
                                <BasicText fontWeight={700} textAlign={'center'}>PLATFORM TYPE</BasicText>
                            </HeaderItemWrap>
                            <HeaderItemWrap style={{ width: '20%' }}>
                                <BasicText fontWeight={700} textAlign={'center'}>PLATFORM VERSION</BasicText>
                            </HeaderItemWrap>
                            <HeaderItemWrap style={{ width: '20%' }}>
                                <BasicText fontWeight={700} textAlign={'center'}>PLATFORM PACKAGENAME</BasicText>
                            </HeaderItemWrap>
                            <HeaderItemWrap style={{ width: '20%' }}>
                                <BasicText fontWeight={700} textAlign={'center'}>PLATFORM DEV STACK</BasicText>
                            </HeaderItemWrap >
                            <HeaderItemWrap style={{ width: '20%' }}>
                                <BasicText fontWeight={700} textAlign={'center'}>PLATFORM LINK</BasicText>
                            </HeaderItemWrap>
                        </TableHeaderWrap>
                        <TableContentWrap>
                            <ContentItemWrap style={{ width: '20%' }}>
                                <BasicText textAlign={'center'}>{item.platform_type}</BasicText>
                            </ContentItemWrap>
                            <ContentItemWrap style={{ width: '20%' }}>
                                <BasicText textAlign={'center'}>{item.platform_version}</BasicText>
                            </ContentItemWrap>
                            <ContentItemWrap style={{ width: '20%' }}>
                                <BasicText textAlign={'center'}>{item.platform_package_name}</BasicText>
                            </ContentItemWrap>
                            <ContentItemWrap style={{ width: '20%' }}>
                                <BasicText textAlign={'center'}>{item.platform_dev_stack}</BasicText>
                            </ContentItemWrap>
                            <ContentItemWrap style={{ width: '20%' }}>
                                <BasicButton sx={{ padding: '0px' }} onPress={() => { window.open(item.platform_link) }}>
                                    <BasicText>LINK</BasicText>
                                </BasicButton>
                            </ContentItemWrap>
                        </TableContentWrap>
                    </PlatformTableWrap>
                ))}
            </PlatformTable>
        </ItemWrap>
    )
}

const ItemWrap = styled.div`
    padding: 10px;
    margin-bottom: 30px;
    border-radius: 10px;
    transition: all ease 0.2s 0s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
`;

const BasicInfoWrap = styled.div`
    display: flex;
    justify-content: space-between;
`

const InfoWrap = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`

const ButtonWrap = styled.div`
    display: flex;
`

const DeleteButtonWrap = styled.div``

const InfoButtonWrap = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 10px;
`

const ProjectIconWrap = styled.div`
    width: 50px;
    height: 50px;
    margin-right: 15px;
    display: flex;
    border-radius: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
`

const ProjectIntroWrap = styled.div``

const PlatformTable = styled(Row)<{showPlatformInfo:boolean}>`
    flex-direction: column;
    gap: 15px;
    margin-top: 15px;
    display: ${({showPlatformInfo}) => showPlatformInfo ? 'flex' : 'none'};
`;
const PlatformTableWrap = styled.div<{ height?: number }>`
    display: flex;
    width: 100%;
    flex-direction: column;
    height: ${(props: any) => `${props.height}px`};
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
`

const TableHeaderWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid rgb(230, 230, 230);
    height: 60%;
`

const HeaderItemWrap = styled.div`
    display: flex;
    justify-content: center;
`

const TableContentWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    height: 40%;
`

const ContentItemWrap = styled.div`
    display: flex;
    justify-content: center;
`