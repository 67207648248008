import styled from "styled-components"
import BasicText from "../../basic/BasicText"
import { useState } from "react"
import BasicButton from "../../basic/BasicButton";
import { apiRegisterFTPInfo } from "../../../api/ftp/ftp_api";
import { userUserAuthPayload } from "../../../utils/custom-hooks/common/userUserAuthPayload";
import { useNavigate } from "react-router";
import TitleWithTextFieldItem from "../../common/item/TitleWithTextFieldItem";
import TitleWithSelectItem from "../../common/item/TitleWithSelectItem";

export default function UploadFTPPart() {
    const authPayload = userUserAuthPayload();
    const navigate = useNavigate();

    const [ftpInfo, setFTPInfo] = useState<any>({
        ftp_type: '1',
    });

    function ftpInfoChangeHandler(key: string, value: string) {
        setFTPInfo((prev: any) => {

            return {
                ...prev,
                [key]: value,
            }
        })
    }

    function submitFTPInfoHandler() {
        apiRegisterFTPInfo(ftpInfo, authPayload).then(res => {
            if (res.result) {
                alert("FTP Uploaded successfully!");
                navigate('/ftp');
            }
            else {
                alert(res.error_en);
            }
        })
    }

    return (
        <PartWrap>
            <PartHeaderWrap>
                <BasicText fontSize={30} fontWeight={700}>UPLOAD FTP</BasicText>
            </PartHeaderWrap>
            <InputBoxWrap>
                <FTPInputWrap>
                    <TitleWithTextFieldItem value={ftpInfo.ftp_name} title={'FTP NAME'} onChange={(value: string) => { ftpInfoChangeHandler('ftp_name', value); }} />
                </FTPInputWrap>
                <FTPInputWrap>
                    <TitleWithTextFieldItem value={ftpInfo.ftp_host_name} title={'FTP HOST NAME'} onChange={(value: string) => { ftpInfoChangeHandler('ftp_host_name', value); }} />
                </FTPInputWrap>
                <FTPInputWrap>
                    <TitleWithTextFieldItem value={ftpInfo.ftp_domain_name} title={'FTP DOMAIN NAME'} onChange={(value: string) => { ftpInfoChangeHandler('ftp_domain_name', value); }} />
                </FTPInputWrap>
                <FTPInputWrap>
                    <TitleWithSelectItem
                        title="FTP TYPE"
                        value={ftpInfo.ftp_type}
                        onChange={(value: string) => ftpInfoChangeHandler('ftp_type', value)}
                        selectItems={[
                            { value: '1', title: 'Single Page' },
                            { value: '2', title: 'App(Android)' },
                            { value: '3', title: 'App(IOS)' },
                            { value: '4', title: 'Web' },
                            { value: '5', title: 'Admin' },
                            { value: '6', title: 'API Server' }]}
                    />
                </FTPInputWrap>
                <FTPInputWrap>
                    <TitleWithTextFieldItem value={ftpInfo.ftp_port} title={'FTP PORT'} onChange={(value: string) => { ftpInfoChangeHandler('ftp_port', value); }} />
                </FTPInputWrap>
                <FTPInputWrap>
                    <TitleWithTextFieldItem value={ftpInfo.ftp_remote_port} title={'FTP REMOTE PORT'} onChange={(value: string) => { ftpInfoChangeHandler('ftp_remote_port', value); }} />
                </FTPInputWrap>
                <FTPInputWrap>
                    <TitleWithTextFieldItem value={ftpInfo.ftp_user_name} title={'FTP USER NAME'} onChange={(value: string) => { ftpInfoChangeHandler('ftp_user_name', value); }} />
                </FTPInputWrap>
                <FTPInputWrap>
                    <TitleWithTextFieldItem value={ftpInfo.ftp_word} title={'FTP PASSWORD'} onChange={(value: string) => { ftpInfoChangeHandler('ftp_word', value); }} />
                </FTPInputWrap>
                <FTPInputWrap style={{ marginBottom: '30px' }}>
                    <TitleWithTextFieldItem value={ftpInfo.ftp_description} title={'FTP DESCRIPTION'} onChange={(value: string) => { ftpInfoChangeHandler('ftp_description', value); }} />
                </FTPInputWrap>
                <CryptoKeyInputWrap>
                    <BasicText marginBottom={1} fontWeight={700}>PLEASE ENTER YOUR CRYPTO KEY TO REGISTER FTP :</BasicText>
                    <TitleWithTextFieldItem value={ftpInfo.cryptoKey} inputSize="small" sx={{ width: '50%' }} borderRadius="0px" border={'1px solid gray'} onChange={(value: string) => { ftpInfoChangeHandler('cryptoKey', value); }} />
                </CryptoKeyInputWrap>
                <BasicButton onPress={submitFTPInfoHandler}>
                    <BasicText color={'white'}>Submit</BasicText>
                </BasicButton>
            </InputBoxWrap>
        </PartWrap>
    )
}

const PartWrap = styled.div`
width: 100%;
padding: 30px;
`

const PartHeaderWrap = styled.div`
margin-bottom: 30px;
`

const InputBoxWrap = styled.div`
width: 50%;
`

const FTPInputWrap = styled.div`
margin-bottom: 10px;
`

const CryptoKeyInputWrap = styled.div`
display: flex;
flex-direction: column;
margin-bottom: 10px;
`