import { axiosConfigInstance } from '../config';
import { ErrorHandler } from '../errorHandler';
import { HeaderPayloadType } from '../config';

export async function apiGetProjectList(page: number, count: number, payload: any, dispatch?: any) {
  const res: any = await axiosConfigInstance('/project', payload).post('', {
    method: 'getProjectList',
    params: {
      page, count
    }
  }).catch((error: any) => {
    return {
      result: false,
      code: 1003
    }
  })

  return res.data;
}

export async function apiGetProjectDetailInfo(project_id: any, payload: HeaderPayloadType, dispatch?: any) {
  const res: any = await axiosConfigInstance('/project', payload).post('', {
    method: 'getProjectDetailInfo',
    params: {
      project_id: project_id
    }
  }).catch((err: any) => {
    return {
      result: false,
      error_en: 'Please try again later!'
    }
  })

  return res.data;
}

export async function apiGetRegisterSummaryBaseData(project_id: any, payload: HeaderPayloadType, dispatch?: any) {
  const res: any = await axiosConfigInstance('/project/api', payload).post('', {
    method: 'getRegisterSummaryBaseData',
    params: {
      project_id: project_id
    }
  }).catch((err) => {
    return {
      result: false,
      error_en: 'Please try again later!'
    }
  });

  return res.data;
}

export async function apiGetProjectServerInfo(project_id: string, cryptoKey: string, payload: HeaderPayloadType, dispatch?: any) {
  const res: any = await axiosConfigInstance('/project', payload).post('', {
    method: 'getProjectServerInfo',
    params: {
      project_id,
      cryptoKey,
    }
  }).catch((err: any) => {
    return {
      result: false,
      error_en: 'Please try again later!'
    }
  })

  return res.data;
}

export async function apiModifyProjectBasicInfo(data: any, payload: HeaderPayloadType, dispatch?: any) {
  const formData: any = new FormData();

  formData.append('project_icon', data.project_icon[0]);

  const formatData = {
    project_name: data.project_name,
    project_description: data.project_description,
    project_type: data.project_type,
    project_id: data.project_id,
    project_icon: data.project_icon,
  }

  formData.append('params', JSON.stringify(formatData));
  formData.append('method', 'modifyProjectBasicInfo');

  const res: any = await axiosConfigInstance('/project', payload).post('', formData).catch((err: any) => {
    return {
      result: false,
      error_en: 'Please try again later!'
    }
  })

  return res.data;
}

export async function apiDeleteProject(project_id: string, payload: HeaderPayloadType, dispatch?: any) {
  const res: any = await axiosConfigInstance('/project', payload).post('', {
    method: 'deleteProject',
    params: {
      project_id,
    }
  }).catch((err) => {
    return {
      result: false,
      error_en: 'Please try again later!'
    }
  });

  return res.data;
}