import React from 'react';
import styled from '@emotion/styled';
import BasicText from '../../basic/BasicText';
import Checkbox from '@mui/material/Checkbox';
import TitleWithTextFieldItem from '../../common/item/TitleWithTextFieldItem';
import TitleWithSelectItem from '../../common/item/TitleWithSelectItem';

export default function RegisterParamsItem({ data, index, onParamsValue }: any) {
    return (
        <ItemWrap>
            <TopInputWrap>
                <BasicText fontSize={22} fontWeight={700}>{index + 1}</BasicText>
                <InputTableWrap>
                    <InputHeaderWrap>
                        <HeaderWrap>
                            <BasicText textAlign={'center'} fontSize={14}>PARAMETER KEY</BasicText>
                        </HeaderWrap>
                        <HeaderWrap>
                            <BasicText textAlign={'center'} fontSize={14}>PARAMETER VALUE</BasicText>
                        </HeaderWrap>
                        <HeaderWrap>
                            <BasicText textAlign={'center'} fontSize={14}>PARAMETER TYPE</BasicText>
                        </HeaderWrap>
                        <HeaderWrap style={{ marginRight: '0px' }}>
                            <BasicText textAlign={'center'} fontSize={14}>PARAMETER REQUIREMENT</BasicText>
                        </HeaderWrap>
                    </InputHeaderWrap>
                    <InputContentWrap>
                        <InputWrap>
                            <TitleWithTextFieldItem inputSize='small' value={data.params_key} onChange={(value: string) => onParamsValue('params_key', value, index)} />
                        </InputWrap>
                        <InputWrap>
                            <TitleWithTextFieldItem inputSize='small' value={data.params_value} onChange={(value: string) => onParamsValue('params_value', value, index)} />
                        </InputWrap>
                        <InputWrap>
                            <TitleWithSelectItem inputSize='small' value={data.params_type} onChange={(value: string) => onParamsValue('params_type', value, index)} selectItems={[{ value: 'string', title: 'String' }, { value: 'integer', title: 'Integer' }, { value: 'file', title: 'File' }, { value: 'array', title: 'Array' }]} />
                        </InputWrap>
                        <InputWrap style={{ marginRight: '0px' }}>
                            <Checkbox checked={data.params_select === '1' ? false : true} onChange={(event: React.ChangeEvent<HTMLInputElement>) => onParamsValue('params_select', event.target.checked ? '0' : '1', index)} />
                        </InputWrap>
                    </InputContentWrap>
                </InputTableWrap>
            </TopInputWrap>
            <TitleWithTextFieldItem title='PARAMETER DESCRIPTION' titleFontSize={14} showTextLength={false} value={data.params_description} onChange={(value: string) => onParamsValue('params_description', value, index)} />
        </ItemWrap>
    )
}

const ItemWrap = styled.div`
background-color: #f5f5f5;
padding: 15px;
margin-bottom: 15px;
`;

const TopInputWrap = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
margin-bottom: 10px;
`

const InputTableWrap = styled.div``

const InputHeaderWrap = styled.div`
display: flex;
justify-content: space-between;
margin-bottom: 10px;
`

const HeaderWrap = styled.div`
width: 20%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
`

const InputContentWrap = styled.div`
display: flex;
justify-content: space-between;
`

const InputWrap = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width: 20%;
`