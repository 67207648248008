import styled from 'styled-components';
import BasicText from '../../basic/BasicText';
import { WidthWrap, Row } from '../../../style/basic/commonStyle';

export default function ApiCheckItem({ item, index, onPress }: any) {
    const apiTimestamp = new Date(Number(item.api_timestamp)).toLocaleDateString();

    return (
        <ItemWrap onClick={() => onPress(item)}>
            <WidthWrap width={10} align="center">
                <BasicText theme="gray" fontSize={14} fontWeight={600}>
                    {index + 1}
                </BasicText>
            </WidthWrap>
            <WidthWrap width={70} align="flex-start">
                <BasicText theme="white" fontSize={13} fontWeight={'bold'}>
                    {item.api_name}
                </BasicText>
                <BasicText theme="white" fontSize={11}>
                    {item.api_endpoint}
                </BasicText>
            </WidthWrap>
            <WidthWrap width={20} align="flex-end">
                <BasicText theme="white" fontSize={10}>
                    보안 등급:
                    {item.api_level}
                </BasicText>
                <BasicText theme="gray" marginTop={1} fontSize={8}>
                    {apiTimestamp}
                </BasicText>
            </WidthWrap>
        </ItemWrap>
    )
}


const ItemWrap = styled(Row)`
    width: 100%;
    height: auto;
    border: 1px solid rgba(0, 0, 0, 0.5);
    display: flex;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    transition: 0.5s;

    &:hover {
        background: rgba(0, 255, 255, 0.3);
        cursor: pointer;
    }
`;