import { useEffect, useState } from 'react';
import ProjectListItem from '../item/ProjectListItem';
import styled from 'styled-components';
import { apiDeleteProject, apiGetProjectList } from '../../../api/project/project_info_api';
import { useDispatch } from 'react-redux';
import { userUserAuthPayload } from '../../../utils/custom-hooks/common/userUserAuthPayload';
import BasicButton from '../../basic/BasicButton';
import BasicText from '../../basic/BasicText';
import { useNavigate } from 'react-router';
import TitleWithSelectItem from '../../common/item/TitleWithSelectItem';

export default function ProjectListPart() {
    const dispatch = useDispatch();
    const authPayload = userUserAuthPayload();
    const navigate = useNavigate();

    const [projectList, setProjectList] = useState<any>([]);
    const [filteredList, setFilteredList] = useState<any>([]);
    const [selectItem, setSelectItem] = useState('0');

    useEffect(() => {
        apiGetProjectList(1, 100, authPayload, dispatch).then((res: any) => {
            if (res.result) {
                setProjectList(res.data)
                setFilteredList(res.data)
            } else {
                if (res.code === 1103) {
                    navigate('/login');
                }
            }
        })
    }, []);

    function selectItemHandler(value: string) {
        if (value === '0') setFilteredList(projectList);
        else setFilteredList(projectList.filter((item: any) => item.project_type === value));
        setSelectItem(value);
    }

    function deleteProjectHandler(project_id: string) {
        apiDeleteProject(project_id, authPayload).then((res: any) => {
            if (res.result) {
                alert("Project deleted successfully!");
                apiGetProjectList(1, 100, authPayload, dispatch).then((res: any) => {
                    if (res.result) {
                        setProjectList(res.data)
                        setFilteredList(res.data)
                    } else {
                        alert(res.error_en);
                    }
                })
            } else {
                alert(res.error_en);
            }
        });
    }

    return (
        <PartWrap>
            <BasicText fontSize={30} fontWeight={700} marginBottom={4}>PROJECT LIST</BasicText>
            <PartHeaderWrap>
                <BasicButton onPress={() => { navigate('/project/upload') }}>
                    <BasicText>NEW PROJECT</BasicText>
                </BasicButton>
                <TitleWithSelectItem value={selectItem} onChange={selectItemHandler} borderRadius='5px' style={{ width: '10%' }} selectItems={[{ value: '0', title: 'ALL' }, { value: '1', title: 'PLATFORM' }, { value: '2', title: 'MTC' }, { value: '3', title: 'SERVER' }, { value: '4', title: 'WORKSPACE' }]} />
            </PartHeaderWrap>
            <ProjectGridWrap>
                {filteredList.map((data: any, index: number) => {
                    return (
                        <ProjectListItem onDelete={deleteProjectHandler} key={data.project_id} style={{ marginBottom: index === filteredList.length - 1 && '0px' }} data={data} />
                    )
                })}
            </ProjectGridWrap>
        </PartWrap>
    )
}

const PartWrap = styled.div`
padding: 30px;
`

const PartHeaderWrap = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
margin-bottom: 30px;
`

const ProjectGridWrap = styled.div``;