import { Dialog, DialogActions, DialogContent, Slide } from '@mui/material';
import React, { useRef } from 'react'
import styled from 'styled-components';
import { Row, RowBetween } from '../../../style/basic/commonStyle';
import ApiInfoPart from './ApiInfoPart';
import BasicText from '../../basic/BasicText';
import BasicButton from '../../basic/BasicButton';
import { useNavigate, useParams } from 'react-router';

const Transition = React.forwardRef(function Transition(props:any,ref:any) {
    const refEl = useRef<any>();
    return <Slide direction="up" ref={refEl} {...props} />;
});

export default function ApiDetailPart({open,setOpen,selectedProject}:any) {
    const ref = useRef<any>();
    const { project_id } = useParams();
    const navigate = useNavigate();

    const handleClose = () =>{
        setOpen(false);
    }
    return (
        <Dialog
            ref={ref}
            open={open}
            onClose={handleClose}
            sx={{
                '.MuiDialog-container':{alignItems:'center'},
                '.MuiPaper-root':{margin:'25px', maxWidth:'1080px',width:'100%',borderRadius: '6px'}
            }}
            TransitionComponent={Transition}
        >
            <DialogActions>
                <TopWrap>
                    <BasicText fontSize={24} fontWeight={700}>API INFO</BasicText>
                    <TopBtnWrap>
                        <BasicButton onPress={() => { navigate(`/project/${project_id}/apis/${selectedProject.api_id}`) }}>
                            <BasicText>EDIT</BasicText>
                        </BasicButton>
                        <BasicButton onPress={handleClose}>
                            <BasicText>CLOSE</BasicText>
                        </BasicButton>
                    </TopBtnWrap>
                </TopWrap>
            </DialogActions>
            <DialogContent>
                <ApiInfoPart selectedProject={selectedProject}></ApiInfoPart>
            </DialogContent>
        </Dialog>
    )
}

const TopWrap = styled(RowBetween)`
    padding: 10px;
    width: 100%;
`;

const TopBtnWrap = styled(Row)`
    width: auto;
    gap: 15px;
`;