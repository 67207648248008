import {createGlobalStyle, css} from 'styled-components';
import StyledReset from 'styled-reset';
import {MyReset} from './my-reset';

const font = css`

  @font-face {
    font-family: EnglishFont;
  }
  
  @font-face {
    font-family: ChineseFont;
  }
  
  body {
    font-family: "Malgun Gothic", sans-serif;
    font-size: 15px;
    
    &.en, .en {
      font-family: EnglishFont, sans-serif;
      line-height: 1.2;
    }
    
    &.cn, .cn {
      font-family: ChineseFont, sans-serif;
    }
  }
`;

const layout = css`
  
  html, body, #root {
    height: 100%;
  }
  
  #root {
    display: flex;
    flex-direction: column;
  }
`;

const reset = css`
  ${StyledReset};
  ${MyReset};
`;

export const notSelect = css`
  // -webkit-user-select: none;
  // -moz-user-select: none;
`;

export const GlobalStyle = createGlobalStyle`

  ${reset};
  ${layout};

  a {
    color: inherit;
  }
  
  body {
    // padding: 10px;
    // ${notSelect}
    background-repeat: no-repeat;
    background-size: 100% 100%;
  //   ::-webkit-scrollbar {
  //     display: none;
  // }
`;

