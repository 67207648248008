import { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import BasicText from '../../basic/BasicText';
import RegisterParamsItem from '../item/RegisterParamsItem';
import { apiRegisterProjectApi } from '../../../api/project/project_api_api';
import { apiGetRegisterSummaryBaseData } from '../../../api/project/project_info_api';
import { useDispatch } from 'react-redux';
import { userUserAuthPayload } from '../../../utils/custom-hooks/common/userUserAuthPayload';
import { useNavigate, useParams } from 'react-router';
import BasicButton from '../../basic/BasicButton';
import TitleWithTextFieldItem from '../../common/item/TitleWithTextFieldItem';
import TitleWithSelectItem from '../../common/item/TitleWithSelectItem';
import TitleWithTextAreaItem from '../../common/item/TitleWithTextAreaItem';

export default function RegisterApiPart({ project_id }: any) {
    const dispatch = useDispatch();
    const authPayload = userUserAuthPayload();
    const params: any = useParams();
    const navigate = useNavigate();

    const [apiBaseURL, setApiBaseURL] = useState<string>('');

    const [apiInfo, setApiInfo] = useState<any>({
        api_use_type: '1',
        api_level: '5',
        api_params: [{
            params_type: 'string',
            params_select: '0',
        }],
    });

    useEffect(() => {
        apiGetRegisterSummaryBaseData(project_id, authPayload, dispatch).then(response => {
            if (response.result) {
                setApiBaseURL(response.data.project_baseURL);
            }
        })
    }, []);

    const onSubmitAPI = async () => {
        apiRegisterProjectApi(params.project_id, { ...apiInfo, api_baseurl: apiBaseURL }, authPayload, dispatch).then(response => {
            if (response.result) {
                alert('성공적으로 등록되었습니다.');
                navigate(`/project/${params.project_id}/apis`);
            } else {
                alert(`${response.data.error}`);
            }
        })
    }

    const paramsChangeAction = (action: string) => {
        if (action === 'add') {
            setApiInfo((prev: any) => {
                return {
                    ...prev,
                    api_params: [...prev.api_params, {
                        params_type: 'string',
                        params_select: '0',
                    }]
                }
            });
        } else {
            setApiInfo((prev: any) => {
                prev.api_params.pop();
                return {
                    ...prev
                }
            })
        }
    }

    function updateApiInfoHandler(key: string, value: any, index?: number) {
        setApiInfo((prev: any) => {
            if (index !== undefined) {
                prev.api_params[index][key] = value;

                return {
                    ...prev
                }
            }

            return {
                ...prev,
                [key]: value
            }
        })
    }

    return (
        <PartWrap>
            <PartHeaderWrap>
                <BasicText fontSize={30} fontWeight={700}>UPLOAD API</BasicText>
            </PartHeaderWrap>
            <UploadInputWrap>
                <BasicInfoWrap>
                    <TitleWithTextFieldItem value={apiInfo.api_name} title='API NAME' showTextLength={false} onChange={(value: any) => updateApiInfoHandler('api_name', value)} style={{ marginBottom: '15px' }} />
                    <TitleWithTextFieldItem value={apiInfo.api_description} title='API DESCRIPTION' showTextLength={false} onChange={(value: any) => updateApiInfoHandler('api_description', value)} style={{ marginBottom: '15px' }} />
                    <TitleWithTextFieldItem value={apiInfo.api_endpoint} title='API ENDPOINT' showTextLength={false} onChange={(value: any) => updateApiInfoHandler('api_endpoint', value)} style={{ marginBottom: '15px' }} />
                    <TitleWithTextFieldItem value={apiInfo.api_method} title='API METHOD' showTextLength={false} onChange={(value: any) => updateApiInfoHandler('api_method', value)} style={{ marginBottom: '15px' }} />
                    <TitleWithSelectItem title='API USE TYPE' value={apiInfo.api_use_type} onChange={(value: any) => { updateApiInfoHandler('api_use_type', value); }} selectItems={[{ value: '1', title: '클라이언트' }, { value: '2', title: '관리자' }]} style={{ marginBottom: '15px' }} />
                    <TitleWithSelectItem title='API LEVEL' value={apiInfo.api_level} onChange={(value: any) => { updateApiInfoHandler('api_level', value); }} selectItems={[{ value: '1', title: '1' }, { value: '2', title: '2' }, { value: '3', title: '3' }, { value: '4', title: '4' }, { value: '5', title: '5' }]} style={{ marginBottom: '15px' }} />
                    <TitleWithTextAreaItem value={apiInfo.api_return_value} title='API RETURN' showTextLength={false} onChange={(value: any) => updateApiInfoHandler('api_return_value', value)} />
                </BasicInfoWrap>
                <ParamsInfoWrap>
                    <InfoHeaderWrap>
                        <BasicText fontSize={22} fontWeight={700}>PARAMS INFO</BasicText>
                        <div>
                            <BasicButton onClick={() => paramsChangeAction('add')} sx={{ marginRight: '10px' }}>
                                <BasicText>+</BasicText>
                            </BasicButton>
                            <BasicButton onClick={() => paramsChangeAction('remove')}>
                                <BasicText>-</BasicText>
                            </BasicButton>
                        </div>
                    </InfoHeaderWrap>
                    <ParamsListWrap>
                        {apiInfo.api_params.map((data: any, index: number) => {
                            return (
                                <RegisterParamsItem key={`params-input-${index}`} data={data} index={index} onParamsValue={updateApiInfoHandler} />
                            )
                        })}
                    </ParamsListWrap>
                </ParamsInfoWrap>
                <BasicButton onClick={onSubmitAPI}>
                    <BasicText>등록</BasicText>
                </BasicButton>
            </UploadInputWrap>
        </PartWrap>
    )
}

const PartWrap = styled.div`
padding: 30px;
width: 100%;
`;

const PartHeaderWrap = styled.div`
margin-bottom: 30px;
`

const UploadInputWrap = styled.div``

const BasicInfoWrap = styled.div`
width: 50%;
margin-bottom: 30px;
`

const ParamsInfoWrap = styled.div`
width: 50%;
border-radius: 10px;
padding: 30px;
margin-bottom: 30px;
box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
`

const InfoHeaderWrap = styled.div`
display: flex;
justify-content: space-between;
margin-bottom: 15px;
`

const ParamsListWrap = styled.div``