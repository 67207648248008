export const ErrorHandler = (res: any) => {
    if (res.data.code === 1000) {
        // 정상
        return {
            result: true,
            error: '',
            ...res.data
        };
    }

    if (res.data.code !== 1000) {
        return {
            result: false,
            ...res.data,
            data: {}
        }
    }

    // if (res.data.code === 1001){
    //     // 파라미터 누락
    //     return {
    //         result: false,
    //         error: '해당 항목을 전부 입력해주세요.',
    //         data: {}
    //     };
    // } 
    // if (res.data.code === 1002){
    //     // 정보가 일치하지 않을 때
    //     return {
    //         result: false,
    //         error: '정보가 일치하지 않습니다.',
    //         data: {}
    //     };
    // }
    //  if (res.data.code === 1003){
    //     // 가입된 정보가 없을 때
    //     return {
    //         result: false,
    //         error: '정보가 없습니다.',
    //         data: {}
    //     };
    // }
    //  if (res.data.code === 1004){
    //     // 이미 가입된 계정이 있을 때
    //     return {
    //         result: false,
    //         error: '이미 가입된 계정이 있습니다.',
    //         data: {}
    //     };
    // }
    // if (res.data.code === 1005){
    //     // 이미 가입된 계정이 있을 때
    //     return {
    //         result: false,
    //         error: '인증 시간이 초과되었습니다.',
    //         data: {}
    //     };
    // }
};
