import styled from "@emotion/styled"
import TitleWithTextFieldItem from "../../common/item/TitleWithTextFieldItem";
import TitleWithSelectItem from "../../common/item/TitleWithSelectItem";
import BasicText from "../../basic/BasicText";
import BasicButton from "../../basic/BasicButton";
import { apiEditCommonCode } from "../../../api/commonCode/commonCode_api";
import { userUserAuthPayload } from "../../../utils/custom-hooks/common/userUserAuthPayload";

export default function SelectedCommonCodePart({ selectedCode, setSelectedCode, setShowDetailModal }: any) {
    const authPayload = userUserAuthPayload();

    function setSelectedCodeHandler(key: string, value: string) {
        setSelectedCode((prev: any) => {
            const newData = { ...prev };
            newData[key] = value;

            return newData;
        })
    }

    function editCodeHandler() {
        apiEditCommonCode(selectedCode, authPayload).then((res: any) => {
            if (res.result) alert("수정되었습니다.")
            else alert(res.error_en);
        })
    }

    return (
        <PartWrap>
            <ModalHeaderWrap>
                <BasicText fontSize={24} fontWeight={700}>EDIT COMMON CODE</BasicText>
                <ModalButtonWrap>
                    <BasicButton onPress={editCodeHandler} sx={{ marginRight: '10px' }}>
                        <BasicText>EDIT</BasicText>
                    </BasicButton>
                    <BasicButton onPress={() => { setShowDetailModal(false) }}>
                        <BasicText>CLOSE</BasicText>
                    </BasicButton>
                </ModalButtonWrap>
            </ModalHeaderWrap>
            <TitleWithTextFieldItem title="CODE NAME" value={selectedCode.code_name} onChange={(value: string) => { setSelectedCodeHandler('code_name', value) }} style={{ marginBottom: '15px' }} />
            <TitleWithTextFieldItem title="CODE NUMBER" value={selectedCode.code_number} onChange={(value: string) => { setSelectedCodeHandler('code_number', value) }} style={{ marginBottom: '15px' }} />
            <TitleWithSelectItem title="CODE TYPE" value={selectedCode.code_type} onChange={(value: string) => { setSelectedCodeHandler('code_type', value) }} style={{ marginBottom: '15px' }} selectItems={[{ value: '1', title: 'SUCCESS' }, { value: '2', title: 'ERROR' }]} />
            <TitleWithTextFieldItem title="CODE DESCRIPTION" value={selectedCode.code_description} onChange={(value: string) => { setSelectedCodeHandler('code_description', value) }} style={{ marginBottom: '15px' }} />
            <TitleWithTextFieldItem title="CODE ERROR DESCRIPTION(ENGLISH)" value={selectedCode.code_error_text_en} onChange={(value: string) => { setSelectedCodeHandler('code_error_text_en', value) }} style={{ marginBottom: '15px' }} />
            <TitleWithTextFieldItem title="CODE ERROR DESCRIPTION(OTHER LANGUAGES)" value={selectedCode.code_error_text_kr} onChange={(value: string) => { setSelectedCodeHandler('code_error_text_kr', value) }} style={{ marginBottom: '15px' }} />
            <TitleWithSelectItem title="CODE COMMON" value={selectedCode.code_common} onChange={(value: string) => { setSelectedCodeHandler('code_common', value) }} style={{ marginBottom: '15px' }} selectItems={[{ value: '1', title: 'COMMON' }, { value: '0', title: 'UNCOMMON' }]} />
        </PartWrap>
    )
}

const PartWrap = styled.div``

const ModalHeaderWrap = styled.div`
display: flex;
justify-content: space-between;
margin-bottom: 30px;
`

const ModalButtonWrap = styled.div``