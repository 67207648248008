import styled from "@emotion/styled"

export default function GlobalModalPart({ openDrawer, children }: any) {
    return (
        <PartWrap openDrawer={openDrawer}>
            <ModalBoxWrap>
                {children}
            </ModalBoxWrap>
        </PartWrap>
    )
}

const PartWrap = styled.div<{ openDrawer?: boolean }>` // LeftNavBar가 늘어나고 줄어들때를 대비해서 모달창의 너비를 유동적으로 수정, LeftNavBar가 늘어났는지 줄어들었는지를 확인하기 위해 openDrawer props로 상태를 넘겨주어야함
position: absolute;
padding-top: ${(props: any) => props.theme.headerHeight};
left: 0;
top: 0;
background: rgba(0, 0, 0, 0.5);
width: 100%;
height: 100%;
align-items: center;
justify-content: center;
display: flex;
flex-direction: column;
z-index: 999;
transition: ${(props: any) => props.theme.transitions.create('padding', {
    easing: props.theme.transitions.easing.sharp,
    duration: props.theme.transitions.duration.leavingScreen,
})};
${(props: any) => {
        return props.openDrawer && {
            transition: props.theme.transitions.create('padding', {
                easing: props.theme.transitions.easing.easeOut,
                duration: props.theme.transitions.duration.enteringScreen,
            }),
            paddingLeft: `calc(${props.theme.drawerWidth} + 50px)`,
        }
    }};
`

const ModalBoxWrap = styled.div`
width: 80%;
height: 80%;
background-color: white;
border-radius: 10px;
overflow: auto;
padding: 30px;
`