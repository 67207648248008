import styled from "@emotion/styled"
import BasicText from "../../basic/BasicText"
import BasicButton from "../../basic/BasicButton"
import { useState } from "react";
import { apiGetCommentList, apiWriteComment } from "../../../api/project/project_api_api";
import TitleWithTextFieldItem from "./TitleWithTextFieldItem";

export default function CommentBoxItem({ selectedProject, authPayload, setCommentList, children }: any) {
    const [comment, setComment] = useState<string>('');

    function enterCommentHandler() {
        if (comment === '') {
            alert('Please enter empty field!');
            return;
        }

        apiWriteComment(selectedProject.api_id, comment, authPayload).then((res: any) => {
            if (res.result) {
                alert('Comment uploaded successfully!');

                apiGetCommentList(selectedProject.api_id, 1, 100, authPayload).then((res: any) => {
                    if (res.result) {
                        setCommentList(res.data);
                        setComment('')
                    }
                })
            } else {
                alert(res.error_en);
            }
        })
    }

    return (
        <ItemWrap>
            <BasicText fontSize={15} fontWeight={700}>COMMENT</BasicText>
            <CommentBoxWrap>
                <CommentInputWrap>
                    <TitleWithTextFieldItem
                        value={comment}
                        style={{ flexGrow: 1, marginRight: '10px' }}
                        onChange={(value: string) => { setComment(value) }}
                        placeholder='COMMENT'
                        inputSize='small'
                        showTextLength={false} />
                    <BasicButton size='medium' onPress={enterCommentHandler}>ENTER</BasicButton>
                </CommentInputWrap>
                <CommentListWrap>
                    {children}
                </CommentListWrap>
            </CommentBoxWrap>
        </ItemWrap>
    )
}

const ItemWrap = styled.div``

const CommentBoxWrap = styled.div`
display: flex;
flex-direction: column;
border-radius: 10px;
`

const CommentListWrap = styled.div``

const CommentInputWrap = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
margin-bottom: 10px;
`