import { useState } from 'react';
import BasicText from '../../basic/BasicText';
import styled from 'styled-components';
import BasicButton from '../../basic/BasicButton';
import { useDispatch } from 'react-redux';
import { setUserInfo } from '../../../store/common/user';
import { useNavigate } from 'react-router';
import { apiUserLogin } from '../../../api/user/user_api';
import TitleWithTextFieldItem from '../../common/item/TitleWithTextFieldItem';
import { Button } from '@mui/material';

export default function LoginPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    function userLoginHandler(e:any) {
        e.preventDefault();
        apiUserLogin(email, password).then((res: any) => {
            if (res.result) {
                dispatch(setUserInfo(res.data));
                navigate('/');
            } else alert(res.error);
        })
    }

    return (
        <PageWrap>
            <LoginBoxWrap onSubmit={userLoginHandler}>
                <BoxTitleWrap>
                    <BasicText fontSize={40} fontWeight={700}>Login</BasicText>
                </BoxTitleWrap>
                <BoxContentWrap>
                    <EmailInputWrap>
                        <TitleWithTextFieldItem value={email} onChange={(value: string) => setEmail(value)} showTextLength={false} title='Email' />
                    </EmailInputWrap>
                    <PasswordInputWrap>
                        <TitleWithTextFieldItem value={password} onChange={(value: string) => setPassword(value)} showTextLength={false} title='Password' type="password" />
                    </PasswordInputWrap>
                </BoxContentWrap>
                <BoxFooterWrap>
                    <LoginButtonWrap>
                        <BasicButton type='submit'>
                            <BasicText>Login</BasicText>
                        </BasicButton>
                    </LoginButtonWrap>
                </BoxFooterWrap>
            </LoginBoxWrap>
        </PageWrap>
    )
}

const PageWrap = styled.div`
    background-color: green;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const LoginBoxWrap = styled.form`
    width: 50%;
    height: 50%;
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`

const BoxTitleWrap = styled.div``

const BoxContentWrap = styled.div`
    width: 70%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const EmailInputWrap = styled.div`
    margin-bottom: 20px;
`

const PasswordInputWrap = styled.div``

const BoxFooterWrap = styled.div``

const LoginButtonWrap = styled.div``