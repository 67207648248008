import styled from "@emotion/styled"
import BasicText from "../../basic/BasicText"
import BasicButton from "../../basic/BasicButton"

export default function BorderListItem({ itemData }: any) {
    return (
        <ItemWrap style={{ borderBottom: 'none' }}>
            <ContentTextWrap style={{ width: '10%' }}>
                <BasicText>{itemData.id}</BasicText>
            </ContentTextWrap>
            <ContentTextWrap style={{ width: '30%' }}>
                <BasicText>{itemData.title}</BasicText>
            </ContentTextWrap>
            <ContentTextWrap style={{ width: '10%' }}>
                <BasicText>{(itemData.writer)}</BasicText>
            </ContentTextWrap>
            <ContentTextWrap style={{ width: '10%' }}>
                <BasicText>{itemData.timestamp}</BasicText>
            </ContentTextWrap>
            <ContentTextWrap style={{ width: '10%' }}>
                <BasicButton sx={{ padding: '5px 10px' }} onPress={() => { }}>
                    <BasicText color={'white'}>DETAIL</BasicText>
                </BasicButton>
            </ContentTextWrap>
        </ItemWrap>
    )
}

const ItemWrap = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
border-bottom: 1px solid black;
padding: 10px;
`

const ContentTextWrap = styled.div`
display: flex;
justify-content: center;
`