import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { themeColor } from './common/theme';
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import session from "redux-persist/lib/storage/session";
import { globalLoadingState } from './common/globalLoading';
import { globalToast } from './common/globalToast';
import { globalHistory } from './common/globalHistory';
import { fullScreen } from './common/fullScreen';
import { apiInformation } from './api/apiInformation';
import {user} from './common/user';

const persistConfig = {
    key: "suboxDev",
    // localStorage에 저장합니다.
    storage:session,
    whitelist: ['user'],
    blacklist: [ ]
};

const rootReducer = combineReducers({
    themeColor,
    globalLoadingState,
    globalToast,
    globalHistory,
    fullScreen,
    apiInformation,
    user
});

export type RootState = ReturnType<typeof rootReducer>;

export default function reducer() {
    return persistReducer(persistConfig, rootReducer);
}
