import styled from "styled-components"
import BasicText from "../../basic/BasicText"
import { useEffect, useState } from "react"
import { apiEditCommonCode, apiGetCommonProtocolCodeList } from "../../../api/commonCode/commonCode_api"
import { userUserAuthPayload } from "../../../utils/custom-hooks/common/userUserAuthPayload"
import BasicButton from "../../basic/BasicButton"
import { useNavigate } from "react-router"
import GlobalModalPart from "../../common/part/GlobalModalPart"
import SelectedCommonCodePart from "./SelectedCommonCodePart"

export default function CommonCodeListPart({ openBar }: any) {
    const authPayload = userUserAuthPayload();
    const navigate = useNavigate();

    const [commonCodeList, setCommonCodeList] = useState<any>();
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [selectedCode, setSelectedCode] = useState();

    useEffect(() => {
        apiGetCommonProtocolCodeList(1, 10, authPayload).then((res: any) => {
            if (res.result) setCommonCodeList(res.data);
            else alert(res.error_en);
        })
    }, [])

    return (
        <PartWrap>
            <PartHeaderWrap>
                <BasicText fontSize={30} fontWeight={700}>COMMON CODE LIST</BasicText>
                <BasicButton onPress={() => { navigate('/code/upload') }}>
                    <BasicText>ADD NEW CODE</BasicText>
                </BasicButton>
            </PartHeaderWrap>
            <TableWrap>
                <TableHeaderWrap>
                    <HeaderContentWrap style={{ width: '10%' }}>
                        <BasicText>CODE</BasicText>
                    </HeaderContentWrap>
                    <HeaderContentWrap style={{ width: '20%' }}>
                        <BasicText>CODE NAME</BasicText>
                    </HeaderContentWrap>
                    <HeaderContentWrap style={{ width: '20%' }}>
                        <BasicText>CODE ERROR</BasicText>
                    </HeaderContentWrap>
                    <HeaderContentWrap style={{ width: '30%' }}>
                        <BasicText>CODE DESCRIPTION</BasicText>
                    </HeaderContentWrap>
                    <HeaderContentWrap style={{ width: '10%' }}>
                        <BasicText>EDIT</BasicText>
                    </HeaderContentWrap>
                </TableHeaderWrap>
                <TableContentWrap>
                    {commonCodeList && commonCodeList.map((item: any, index: number) => (
                        <ContentGroupWrap key={`${Math.random()}${index}`} style={{ borderBottom: index === commonCodeList.length - 1 ? 'none' : '1px solid rgb(220, 220, 220)' }}>
                            <ContentTextWrap style={{ width: '10%' }}>
                                <BasicText>{item.code_number}</BasicText>
                            </ContentTextWrap>
                            <ContentTextWrap style={{ width: '20%' }}>
                                <BasicText>{item.code_name}</BasicText>
                            </ContentTextWrap>
                            <ContentTextWrap style={{ width: '20%' }}>
                                <BasicText>{item.code_error_text_en}</BasicText>
                            </ContentTextWrap>
                            <ContentTextWrap style={{ width: '30%' }}>
                                <BasicText>{item.code_description}</BasicText>
                            </ContentTextWrap>
                            <ContentTextWrap style={{ width: '10%' }}>
                                <BasicButton onPress={() => { setShowDetailModal(true); setSelectedCode(item); }}>
                                    <BasicText>EDIT</BasicText>
                                </BasicButton>
                            </ContentTextWrap>
                        </ContentGroupWrap>
                    ))}
                </TableContentWrap>
            </TableWrap>
            {showDetailModal && (
                <GlobalModalPart openDrawer={openBar}>
                    <SelectedCommonCodePart selectedCode={selectedCode} setSelectedCode={setSelectedCode} setShowDetailModal={setShowDetailModal} />
                </GlobalModalPart>
            )}
        </PartWrap>
    )
}

const PartWrap = styled.div`
width: 100%;
padding: 30px;
`

const PartHeaderWrap = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
margin-bottom: 30px;
`

const TableWrap = styled.div`
border: 1px solid black;
width: 100%;
`

const TableHeaderWrap = styled.div`
display: flex;
justify-content: space-between;
background-color: black;
color: white;
padding: 10px;
`

const HeaderContentWrap = styled.div`
display: flex;
justify-content: center;
`

const TableContentWrap = styled.div`
`

const ContentGroupWrap = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
border-bottom: 1px solid black;
padding: 10px;
`

const ContentTextWrap = styled.div`
display: flex;
justify-content: center;
`