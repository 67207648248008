import styled from "@emotion/styled"
import TitleWithTextFieldItem from "../../common/item/TitleWithTextFieldItem"
import BasicText from "../../basic/BasicText"
import { IconButton } from "@mui/material"
import BasicButton from "../../basic/BasicButton"
import EditIcon from '@mui/icons-material/Edit';
import { useState } from "react"
import DeleteIcon from '@mui/icons-material/Delete';

export default function ApiCommentItem({ commentListItem, onEdit, onDelete }: any) {
    const [commentEditMode, setCommentEditMode] = useState(false);
    const [commentValue, setCommentValue] = useState(commentListItem.comment_content);

    function enterEditModeHandler() {
        setCommentEditMode(true);
    }

    function exitEditModeHandler() {
        setCommentEditMode(false);
    }

    function editCommentHandler() {
        onEdit(commentListItem.comment_id, commentValue);
    }

    function deleteCommentHandler() {
        onDelete(commentListItem.comment_id);
    }

    return (
        <ItemWrap>
            <CommentWrap>
                {commentEditMode ? <TitleWithTextFieldItem style={{ marginRight: '10px' }} onChange={(value: string) => { setCommentValue(value) }} inputSize="small" value={commentValue} /> : <BasicText>{commentListItem.comment_content}</BasicText>}
            </CommentWrap>
            
            {commentEditMode ? (
                <EditButtonWrap>
                    <BasicButton onPress={editCommentHandler} variant="text" size="small" sx={{ marginRight: '10px' }}>
                        <BasicText>EDIT</BasicText>
                    </BasicButton>
                    <BasicButton variant="text" size="small" onPress={exitEditModeHandler}>
                        <BasicText>CANCEL</BasicText>
                    </BasicButton>
                </EditButtonWrap>
            ) : (
                <IconButton size='small' onClick={enterEditModeHandler}>
                    <EditIcon />
                </IconButton>
            )}
            <IconButton size="small" onClick={deleteCommentHandler}>
                <DeleteIcon />
            </IconButton>
            <CommentTimestampWrap>
                <BasicText fontSize={14}>{new Date(Number(commentListItem.comment_timestamp)).toLocaleString()}</BasicText>
            </CommentTimestampWrap>
        </ItemWrap>
    )
}

const ItemWrap = styled.div`
display: flex;
padding: 10px;
background: rgb(240, 240, 240);
align-items: center;
`

const CommentWrap = styled.div`
flex-grow: 1;
`

const EditButtonWrap = styled.div`
display: flex;
align-items: center;
`

const CommentTimestampWrap = styled.div`
width: 20%;
display: flex;
justify-content: center;
`