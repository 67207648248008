import { createAction, handleActions } from 'redux-actions';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../store';

const SET_API_INFORMATION_OPEN = 'subox-dev/api/SET_API_INFORMATION_OPEN';
const SET_API_INFORMATION_CLOSE = 'subox-dev/api/SET_API_INFORMATION_CLOSE';

export const setAPIInformationActionCreator = createAction<any>(SET_API_INFORMATION_OPEN);
export const setAPIInformationCloseActionCreator = createAction<any>(SET_API_INFORMATION_CLOSE);

export function setAPIInformation(data: any): ThunkAction<void, RootState, null, ReturnType<typeof setAPIInformationActionCreator>> {
  return async function (dispatch) {
    try {
      dispatch(setAPIInformationActionCreator({
        data
      }));
    } catch (error) {
      console.log(error);
    }
  };
}

export function setAPIInformationClose(): ThunkAction<void, RootState, null, ReturnType<typeof setAPIInformationCloseActionCreator>> {
  return async function (dispatch) {
    try {
      dispatch(setAPIInformationCloseActionCreator({

      }));
    } catch (error) {
      console.log(error);
    }
  };
}

const initialState: any = {
  api_id: '',
  api_name: '',
  api_endpoint: '',
  api_description: '',
  api_level: 0,
  api_timestamp: '',
  api_platform: '',
  api_method: '',
  api_creator: 0,
  api_baseURL: '',
  api_return_value: '',
  api_use_type: '1',
  api_params: [],
  visible: false,
  navigat: () => { }
};

export const apiInformation = handleActions<any, any>({
  [SET_API_INFORMATION_OPEN]: (state, action: ReturnType<typeof setAPIInformationActionCreator>) => ({
    api_id: action.payload.data.api_id ?? state.api_id,
    api_name: action.payload.data.api_name ?? state.api_name,
    api_endpoint: action.payload.data.api_endpoint ?? state.api_endpoint,
    api_description: action.payload.data.api_description ?? state.api_description,
    api_timestamp: action.payload.data.api_timestamp ?? state.api_timestamp,
    api_platform: action.payload.data.api_platform ?? state.api_platform,
    api_method: action.payload.data.api_method ?? state.api_method,
    api_level: action.payload.data.api_level ?? state.api_level,
    api_creator: action.payload.data.api_creator ?? state.api_creator,
    api_baseURL: action.payload.data.api_baseURL ?? state.api_baseURL,
    api_return_value: action.payload.data.api_return_value ?? state.api_return_value,
    api_use_type: action.payload.data.api_use_type ?? state.api_use_type,
    api_params: action.payload.data.api_params ?? state.api_params,
    visible: true
  }),
  [SET_API_INFORMATION_CLOSE]: (state, action: ReturnType<typeof setAPIInformationCloseActionCreator>) => (initialState),
}, initialState);
