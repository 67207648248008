import { HeaderPayloadType, axiosConfigInstance } from "../config";
import { ErrorHandler } from "../errorHandler";

export async function apiGetFTPInfoList(page: number, count: number, payload: HeaderPayloadType, dispatch?: any) {
    const res = await axiosConfigInstance('/ftp', payload).post('', {
        method: 'getFTPInfoList',
        params: {
            page,
            count
        }
    });
    return ErrorHandler(res);
}

export async function apiGetFTPDetailInfo(ftp_id: number, cryptoKey: string, payload: HeaderPayloadType, dispatch?: any) {
    const res = await axiosConfigInstance('/ftp', payload).post('', {
        method: 'getFTPDetailInfo',
        params: {
            ftp_id,
            cryptoKey
        }
    });
    return ErrorHandler(res);
}

export async function apiRegisterFTPInfo(data: any, payload: HeaderPayloadType, dispatch?: any) {
    const res = await axiosConfigInstance('/ftp', payload).post('', {
        method: 'registerFTPInfo',
        params: {
            ...data,
        }
    });
    return ErrorHandler(res);
}

export async function apiEditFTPInfo(ftp_id: number, data: any, cryptoKey: string, payload: HeaderPayloadType, dispatch?: any) {
    const res: any = await axiosConfigInstance('/ftp', payload).post('', {
        method: 'editFTPInfo',
        params: {
            ftp_id,
            ...data,
            cryptoKey,
        }
    }).catch((err) => {
        return {
            result: false,
            error_en: 'Please try again later!'
        }
    });

    return res.data;
}

export async function apiDeleteFTPInfo(ftp_id: number, cryptoKey: string, payload: HeaderPayloadType, dispatch?: any) {
    const res: any = await axiosConfigInstance('/ftp', payload).post('', {
        method: 'deleteFTPInfo',
        params: {
            ftp_id: ftp_id.toString(),
            cryptoKey,
        }
    }).catch((err) => {
        return {
            result: false,
            error_en: 'Please try again later!'
        }
    });

    return res.data;
}