import BasicText from '../../basic/BasicText';
import Dropzone from 'react-dropzone';
import styled from '@emotion/styled';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';

interface TitleWithDropzoneItemType {
    value?: any;
    style?: any;
    isRequired?: boolean;
    title?: string;
    subtext?: string;
    placeholderText?: string;
    onDrop?: any;
    chooseMultiple?: boolean;
    accept?: any;
    disabled?: boolean;
    border?: string;
    hoverBorder?: string;
    focusBorder?: string;
    borderRadius?: string;
    backgroundColor?: string;
}

export default function TitleWithDropzoneItem({ value = undefined, style = undefined, isRequired = false, title = '', subtext = undefined, placeholderText = '', onDrop = undefined, chooseMultiple = false, accept = undefined, disabled = false, border = '1px solid black', hoverBorder = '1px solid black', focusBorder = '1px solid black', borderRadius = '10px', backgroundColor = 'white' }: TitleWithDropzoneItemType) {
    function dropFileHandler(files: any) {
        onDrop(files);
    }

    return (
        <ItemWrap>
            {title && (
                <ItemTitleWrap>
                    <BasicText fontSize={16}>{title}{isRequired && <RequiredInput>*</RequiredInput>}</BasicText>
                </ItemTitleWrap>
            )}
            <Dropzone onDrop={dropFileHandler} multiple={chooseMultiple} accept={accept} disabled={disabled}>
                {({ getRootProps, getInputProps }) => (
                    <DropzoneWrap style={style}
                        border={border}
                        hoverBorder={hoverBorder}
                        focusBorder={focusBorder}
                        borderRadius={borderRadius}
                        backgroundColor={backgroundColor}
                        {...getRootProps()}>
                        <input {...getInputProps()} />
                        {value === undefined ? (
                            <>
                                <CropOriginalIcon sx={{ color: 'rgb(179, 179, 179)', fontSize: '100px' }} />
                                <BasicText theme="white" fontSize={14}>
                                    {placeholderText ?? 'Upload File'}
                                </BasicText>
                            </>
                        ) : (
                            <PreviewImageWrap>
                                <img src={typeof value === 'object' ? URL.createObjectURL(value[0]) : value} style={{ width: '100%', height: '100%' }} />
                            </PreviewImageWrap>
                        )}
                    </DropzoneWrap>
                )}
            </Dropzone>
            {subtext && (
                <ItemSubtextWrap>
                    <BasicText theme="gray" fontSize={14}>{subtext ?? ''}</BasicText>
                </ItemSubtextWrap>
            )}
        </ItemWrap>
    );
}

const ItemWrap = styled.div``

const ItemTitleWrap = styled.div`
margin-bottom: 10px;
`

const RequiredInput = styled.span`
color: red;
`

const DropzoneWrap = styled.div<{ border?: string, hoverBorder?: string, focusBorder?: string, borderRadius?: string, backgroundColor?: string }>`
padding: 10px;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
transition: 0.5s;
outline: none;
border: ${(props: any) => props.border};
border-radius: ${(props: any) => props.borderRadius};
background-color: ${(props: any) => props.backgroundColor};
&:hover {
    border: ${(props: any) => props.hoverBorder};
}
&:focus {
    border: ${(props: any) => props.focusBorder};
}
`;

const ItemSubtextWrap = styled.div`
display: flex;
justify-content: center;
padding: 0px 20px;
margin-top: 10px;
`

const PreviewImageWrap = styled.div`
max-width: 100%;
max-height: 100%;
`;