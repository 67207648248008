import { useEffect, useState } from 'react';
import ProjectInfoPart from '../part/ProjectInfoPart';
import NavigationBarItem from '../../common/item/NavigationBarItem';
import { NAVIGATE_BAR_MENU_ITEMS } from '../../../config/NavigationBarConfig';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';
import { useNavigate } from 'react-router';
import { LEVEL_GET_PROJECT_DETAIL_INFO } from '../../../config/permissionConfig';

export default function ProjectInfoPage() {
    const [openBar, setOpenBar] = useState(true);

    const userInfo = useUserInfo();
    const navigate = useNavigate();

    useEffect(() => {
        if (userInfo.user_level > LEVEL_GET_PROJECT_DETAIL_INFO) {
            alert('권한이 없습니다.');
            navigate(-1);
        }
    }, [])

    return (
        <NavigationBarItem pageLocation={'/project'} menuItems={NAVIGATE_BAR_MENU_ITEMS} openBar={openBar} setOpenBar={setOpenBar}>
            <ProjectInfoPart />
        </NavigationBarItem>
    )
}