import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react'
import styled from 'styled-components'
import { apiTypeConfig } from '../../../config/apiTypeConfig';

export default function ApiInformationItem({data}:any) {
    return (
        <Wrap>
            <TableContainer>
                <Table sx={{ minWidth: '100%' }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{width:'30%'}}>Title</TableCell>
                            <TableCell>Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Level
                            </TableCell>
                            <TableCell>{data.api_level}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Name
                            </TableCell>
                            <TableCell>{data.api_name}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Base URL
                            </TableCell>
                            <TableCell>{data.api_baseURL}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Endpoint
                            </TableCell>
                            <TableCell>{data.api_endpoint}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Request Url 
                            </TableCell>
                            <TableCell>{`${data.api_baseURL}${data.api_endpoint}`}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Method
                            </TableCell>
                            <TableCell>{data.api_method}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Use Type
                            </TableCell>
                            <TableCell>{apiTypeConfig(data.api_use_type)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Desc
                            </TableCell>
                            <TableCell>{data.api_description}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Wrap>
    )
}

const Wrap = styled.div``;