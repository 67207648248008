import styled from "@emotion/styled"
import BasicText from "../../basic/BasicText"
import { SelectChangeEvent } from "@mui/material"
import { useEffect, useState } from "react"
import { dummy_api_list } from "../../../data/api_dummy"
import { apiGetApiDetailInfo, apiGetProjectApiList } from "../../../api/project/project_api_api"
import { useNavigate, useParams } from "react-router"
import { userUserAuthPayload } from "../../../utils/custom-hooks/common/userUserAuthPayload"
import ApiCheckItem from "../item/ApiCheckItem"
import ApiInfoPart from "./ApiInfoPart"
import BasicButton from "../../basic/BasicButton"
import TitleWithTextFieldItem from "../../common/item/TitleWithTextFieldItem"
import TitleWithSelectItem from "../../common/item/TitleWithSelectItem"
import { useUserInfo } from "../../../utils/custom-hooks/common/useUserInfo"
import { LEVEL_GET_API_DETAIL_INFO } from "../../../config/permissionConfig"
import GlobalModalPart from "../../common/part/GlobalModalPart"
import ApiDetailPart from "./ApiDetailPart"

export default function ApiListPart({ openBar }: any) {
    const { project_id } = useParams();
    const authPayload = userUserAuthPayload();
    const navigate = useNavigate();
    const userInfo = useUserInfo();

    const [apiList, setApiList] = useState<any>(dummy_api_list);
    const [apiType, setApiType] = useState('0');
    const [filteredList, setFilteredList] = useState<any>(dummy_api_list);
    const [searchText, setSearchText] = useState('');
    const [showApiDetailModal, setShowApiDetailModal] = useState(false);
    const [selectedProject, setSelectedProject] = useState();

    async function apiInformationDetail(item: any) {
        if (userInfo.user_level > LEVEL_GET_API_DETAIL_INFO) {
            alert('권한이 없습니다.');
            return;
        }

        apiGetApiDetailInfo(item.api_id, authPayload).then(res => {
            setSelectedProject(res.data);
        })

        setShowApiDetailModal(true);
    }

    const selectItemHandler = (event: SelectChangeEvent) => {
        setApiType(event.target.value);
        if (event.target.value === '0') {
            setFilteredList(apiList);
            return;
        }
        const filterApiList = apiList.filter((item: any) => item.api_use_type === event.target.value);
        setFilteredList(filterApiList);
    };

    const changeTextHandler = (value: any) => { // 입력할때마다 정규식 검사를 통해 일치하는 api만 렌더링
        setSearchText(value);
        let res = apiList.filter((it: any): any => new RegExp(value, "i").test(it.api_name) || new RegExp(value, "i").test(it.api_endpoint) || new RegExp(value, "i").test(it.api_method.toString()));
        // api 이름, api method, api endpoint만 검색한다.
        setFilteredList(res);
    }

    useEffect(() => {
        apiGetProjectApiList(project_id, authPayload).then(response => {
            if (response.result) {
                setApiList(response.data);
                setFilteredList(response.data);
                return;
            }
        })
    }, []);

    return (
        <PartWrap>
            <PartHeaderWrap>
                <BasicText fontSize={30} fontWeight={700}>API LIST</BasicText>
                <BasicButton onPress={() => { navigate(`/project/${project_id}/apis/upload`); }}>
                    <BasicText>ADD API</BasicText>
                </BasicButton>
            </PartHeaderWrap>
            <ApiSearchWrap>
                <TitleWithSelectItem style={{ width: '10%' }} value={apiType} onChange={selectItemHandler} selectItems={[{ value: '0', title: '전체' }, { value: '1', title: '클라이언트' }, { value: '2', title: '관리자' }]} />
                <TitleWithTextFieldItem placeholder="API 이름/Endpoint/접근 레벨로 검색" style={{ flexGrow: '1', marginLeft: '30px' }} value={searchText} onChange={changeTextHandler} />
            </ApiSearchWrap>
            <PartGridWrap>
                {filteredList.map((item: any, index: number) => {
                    return (
                        <ApiCheckItem key={index.toString()} item={item} index={index} onPress={apiInformationDetail} />
                    )
                })}
            </PartGridWrap>
            <ApiDetailPart open={showApiDetailModal} setOpen={setShowApiDetailModal} selectedProject={selectedProject} />
        </PartWrap>
    )
}

const PartWrap = styled.div`
padding: 30px;
`

const PartHeaderWrap = styled.div`
margin-bottom: 30px;
display: flex;
justify-content: space-between;
`

const ApiSearchWrap = styled.div`
display: flex;
justify-content: space-between;
margin-bottom: 30px;
`

const PartGridWrap = styled.div`
display: grid;
grid-template-columns: 1fr 1fr 1fr 1fr;
gap: 10px;
`