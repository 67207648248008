import styled from "@emotion/styled"
import BasicText from "../../basic/BasicText"
import { CSSProperties } from "react";

interface HeaderDataType {
    title: string;
    width: string;
}

interface ListTableItemProps {
    headerData: HeaderDataType[];
    children: JSX.Element;
    style?: CSSProperties;
    border?: string;
    headerColor?: string;
}

export default function ListTableItem({ headerData, children, style = undefined, border = '1px solid black', headerColor = 'black' }: ListTableItemProps) {
    return (
        <TableWrap style={{ border, ...style }}>
            <TableHeaderWrap style={{ background: headerColor }}>
                {headerData.map((header: HeaderDataType) => {
                    return (
                        <HeaderContentWrap style={{ width: header.width }}>
                            <BasicText>{header.title}</BasicText>
                        </HeaderContentWrap>
                    )
                })}
            </TableHeaderWrap>
            <TableContentWrap>
                {children}
            </TableContentWrap>
        </TableWrap>
    )
}

const TableWrap = styled.div`
border: 1px solid black;
width: 100%;
`

const TableHeaderWrap = styled.div`
display: flex;
justify-content: space-between;
background-color: black;
color: white;
padding: 10px;
`

const TableContentWrap = styled.div`
`

const HeaderContentWrap = styled.div`
display: flex;
justify-content: center;
`