import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react'
import styled from 'styled-components'

export default function ApiParamsItem({list}:any) {
    return (
        <Wrap>
            <TableContainer>
                <Table sx={{ minWidth: '100%' }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Key</TableCell>
                            <TableCell>Value</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Require</TableCell>
                            <TableCell sx={{width:'30%'}}>Desc</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!(list === undefined || list.length === 0) && list.map((data:any,index:any)=>{
                            return(
                                <TableRow key={`params-${index}`}>
                                    <TableCell component="th" scope="row">
                                        {data.params_key}
                                    </TableCell>
                                    <TableCell>{data.params_value}</TableCell>
                                    <TableCell>{data.params_type}</TableCell>
                                    <TableCell>{data.params_select === '0' ? 'true' : 'false'}</TableCell>
                                    <TableCell>{data.params_description}</TableCell>
                                </TableRow>
                            )
                        })}
                        {/* {list === undefined || list.length === 0 ?
                            <TableRow></TableRow> :
                            {list.map((data:any,index:any)=>{
                                return(
                                    <TableRow key={`params-${index}`}></TableRow>
                                )
                            })}
                        } */}
                        {/* {rows.map((row) => (
                            <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell align="right">{row.calories}</TableCell>
                            <TableCell align="right">{row.fat}</TableCell>
                            <TableCell align="right">{row.carbs}</TableCell>
                            <TableCell align="right">{row.protein}</TableCell>
                            </TableRow>
                        ))} */}
                    </TableBody>
                </Table>
            </TableContainer>
        </Wrap>
    )
}

const Wrap = styled.div``;