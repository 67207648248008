import {RootState} from '../../../store/store';
import {useSelector} from 'react-redux';

function selector(state: RootState) {
    return {
      access_token: state.user.access_token,
      authorization: state.user.authorization
    }
}

export function userUserAuthPayload() {
  return useSelector<RootState, ReturnType<typeof selector>>(selector);
}
