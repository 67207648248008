import { Paper } from '@mui/material';
import styled from '@emotion/styled';
import BasicButton from '../../basic/BasicButton';
import ApiInfoItem from '../item/ApiInfoItem';
import TextMarkDownResultPart from '../../markdown/part/TextMarkdownPart';
import BasicText from '../../basic/BasicText';
import { useNavigate, useParams } from 'react-router';
import SliderPart from '../../common/part/SliderPart';
import { apiTypeConfig } from '../../../config/apiTypeConfig';
import { useEffect, useState } from 'react';
import CommentBoxItem from '../../common/item/CommentBoxItem';
import { apiDeleteComment, apiEditComment, apiGetCommentList } from '../../../api/project/project_api_api';
import { userUserAuthPayload } from '../../../utils/custom-hooks/common/userUserAuthPayload';
import ApiCommentItem from '../item/ApiCommentItem';
import { Row } from '../../../style/basic/commonStyle';
import ApiParamsItem from '../item/ApiParamsItem';
import ApiInformationItem from '../item/ApiInformationItem';

export default function ApiInfoPart({ selectedProject }: any) {
    const [commentList, setCommentList] = useState<any>([]);

    const navigate = useNavigate();
    const authPayload = userUserAuthPayload();
    const { project_id } = useParams();

    let content;

    const JSONdata = '```JSON';
    const space = '      ';
    const nextLine = '\n';

    useEffect(() => {
        if (selectedProject !== undefined) {
            apiGetCommentList(selectedProject.api_id, 1, 100, authPayload).then((res: any) => {
                if (res.result) {
                    setCommentList(res.data);
                }
            })
        }
    }, [selectedProject])

    function editCommentHandler(comment_id: string, newComment: string) {
        apiEditComment(comment_id, newComment, authPayload).then((res: any) => {
            if (res.result) {
                alert('Comment edited successfully!');
                apiGetCommentList(selectedProject.api_id, 1, 100, authPayload).then((res: any) => {
                    if (res.result) {
                        setCommentList(res.data);
                    }
                })
            } else {
                alert(res.error_en);
            }
        })
    }

    function deleteCommentHandler(comment_id: string) {
        apiDeleteComment(comment_id, authPayload).then((res: any) => {
            if (res.result) {
                alert('Comment deleted successfully!');
                apiGetCommentList(selectedProject.api_id, 1, 100, authPayload).then((res: any) => {
                    if (res.result) {
                        setCommentList(res.data);
                    }
                })
            } else {
                alert(res.error_en);
            }
        })
    }

    if (selectedProject !== undefined) { // api의 request 부분을 실제로 복사 후 붙여넣기가 가능하게 보여주기 위해 콘텐츠 내용을 코드형식으로 수정
        const contentLine = selectedProject.api_params.map((item: any) => {
            const content = nextLine + space + space + '"' + item.params_key + '": ' + (item.params_type === 'string' ? '"' : '') + item.params_value + (item.params_type === 'string' ? '"' : '');

            return content
        });

        content = `${nextLine + '{' + nextLine + space + '"method"' + ': ' + '"' + selectedProject.api_method + '",' + nextLine + space + '"params"' + ': ' + '{' + contentLine + nextLine + space + '}' + nextLine + '}'}`
    }

    return (
        <PartWrap>
            {/* <ModalHeaderWrap>
                <BasicText fontSize={24} fontWeight={700}>API INFO</BasicText>
                <ModalButtonWrap>
                    <BasicButton onPress={() => { navigate(`/project/${project_id}/apis/${selectedProject.api_id}`) }} sx={{ marginRight: '10px' }}>
                        <BasicText>EDIT</BasicText>
                    </BasicButton>
                    <BasicButton onPress={() => { setShowApiDetailModal(false) }}>
                        <BasicText>CLOSE</BasicText>
                    </BasicButton>
                </ModalButtonWrap>
            </ModalHeaderWrap> */}
            {selectedProject && (
                <ModalContentWrap>
                    <ApiInfoWrap>
                        <BasicInfoWrap>
                            <TitleWrap>
                                <BasicText fontSize={15} fontWeight={700}>INFO</BasicText>
                            </TitleWrap>
                            <InfoWrap>
                                <ApiInformationItem data={selectedProject} />
                                {/* <ApiInfoItem title="API LEVEL" content={selectedProject.api_level} />
                                <ApiInfoItem title="API NAME" content={selectedProject.api_name} />
                                <ApiInfoItem title="BASE URL" content={selectedProject.api_baseURL} />
                                <ApiInfoItem title="ENDPOINT" content={selectedProject.api_endpoint} />
                                <ApiInfoItem title="REQUEST URL" content={`${selectedProject.api_baseURL}${selectedProject.api_endpoint}`} />
                                <ApiInfoItem title="METHOD" content={selectedProject.api_method} />
                                <ApiInfoItem title="USE TYPE" content={apiTypeConfig(selectedProject.api_use_type)} />
                                <ApiInfoItem title="API DESCRIPTION" content={selectedProject.api_description} style={{ borderBottom: '1px solid rgb(230, 230, 230)' }} /> */}
                            </InfoWrap>
                        </BasicInfoWrap>
                        <ParamsInfoWrap>
                            <TitleWrap>
                                <BasicText fontSize={15} fontWeight={700}>PARAMETERS</BasicText>
                            </TitleWrap>
                            {/* api 파라미터들은 슬라이더 라이브러리를 사용하여 렌더링 */}
                            {/* <SlideWrap>
                                <SliderPart style={{ width: '100%', padding: selectedProject.api_params.length <= 1 ? '0' : '0px 30px' }} slideItemStyle={{ height: '100%' }} slidesToShow={1} showArrow>
                                    {selectedProject.api_params.map((item: any, index: number) => (
                                        <div key={Math.random()}>
                                            <ApiInfoItem title={"PARAMETER KEY"} content={item.params_key} />
                                            <ApiInfoItem title={"PARAMETER VALUE"} content={item.params_value} />
                                            <ApiInfoItem title={"PARAMETER TYPE"} content={item.params_type} />
                                            <ApiInfoItem title={"PARAMETER REQUIREMENT"} content={item.params_select === '0' ? 'true' : 'false'} />
                                            <ApiInfoItem title={"PARAMETER DESCRIPTION"} content={item.params_description} style={{ borderBottom: '1px solid rgb(230, 230, 230)' }} />
                                        </div>
                                    ))}
                                </SliderPart>
                            </SlideWrap> */}
                            <ParamsWrap>
                                <ApiParamsItem list={selectedProject?.api_params} />
                            </ParamsWrap>
                        </ParamsInfoWrap>
                    </ApiInfoWrap>
                    <ApiMarkdownWrap>
                        <ApiRequestWrap>
                            <BasicText fontSize={15} fontWeight={700}>REQUEST</BasicText>
                            <TextMarkDownResultPart result={`${JSONdata + content}`} />
                        </ApiRequestWrap>
                        <ApiResponseWrap>
                            <BasicText fontSize={15} fontWeight={700}>RESPONSE</BasicText>
                            <TextMarkDownResultPart result={`${selectedProject.api_return_value}`} />
                        </ApiResponseWrap>
                    </ApiMarkdownWrap>
                    <CommentBoxItem selectedProject={selectedProject} authPayload={authPayload} setCommentList={setCommentList}>
                        {commentList.map((item: any) => {
                            return <ApiCommentItem key={Math.random()} commentListItem={item} onEdit={editCommentHandler} onDelete={deleteCommentHandler} />
                        })}
                    </CommentBoxItem>
                </ModalContentWrap>
            )}
        </PartWrap>
    )
}

const PartWrap = styled.div``

const ModalHeaderWrap = styled.div`
display: flex;
justify-content: space-between;
margin-bottom: 30px;
`

const ModalButtonWrap = styled.div``

const ModalContentWrap = styled.div``

const ApiInfoWrap = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    gap: 15px;
    flex-wrap: wrap;
`

const BasicInfoWrap = styled.div`
width: 100%;
padding: 10px;
display: flex;
flex-direction: column;
border: 1px solid #eee;
border-radius: 6px;
`

const InfoWrap = styled.div`
`

const TitleWrap = styled.div`
margin-bottom: 10px;
`

const SlideWrap = styled.div`
flex-grow: 1;
display: flex;
flex-direction: column;
justify-content: start;
align-items: center;
`

const ParamsInfoWrap = styled.div`
display: flex;
flex-direction: column;
padding: 10px;
width: 100%;
border: 1px solid #eee;
border-radius: 6px;
`

const ApiMarkdownWrap = styled.div`
margin-bottom: 10px;
`

const ApiRequestWrap = styled.div`
margin-bottom: 10px;
`

const ApiResponseWrap = styled.div``

const ParamsWrap = styled(Row)`
    flex-direction: column;
    gap: 15px;
    width: 100%;
`;

const ParamsItemWrap = styled.div`
    width: 100%;
`;