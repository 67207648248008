import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import {RootState} from './../store';
import {theme} from '../../style/extends/themeColor';

const SET_CUSTOM_THEME = 'mesh-dash/theme/SET_CUSTOM_THEME';

export const themeActionCreator = createAction(SET_CUSTOM_THEME);

export function thunkTheme(themecolor: string): ThunkAction<void, RootState, null, ReturnType<typeof themeActionCreator>> {
    return async function (dispatch) {
    try {
            dispatch(themeActionCreator({
                themecolor: themecolor
            }));
    } catch (error) {
      
    }
  };
}

export interface ThemeState {
    themecolor?: any;
}

const initialState: ThemeState = {
    themecolor: {
        fixblack: '#000',
        fixwhite: '#FFF',
        black: '#FFF',
        white: '#000',
        red: '#eb4034',
        container: '#FFF',
        main: 'rgba(0, 223, 255, 1.0)',
        mainLight: 'rgba(0, 223, 255, 0.3)',
        submain: 'rgba(11, 160, 249, 1.0)',
        gray: 'gray',
        modalContainer: 'rgba(0, 0, 0, 0.8)',
        hover: 'rgba(0, 223, 255, 1.0)',
        state_green: ['rgba(135, 245, 66, 1.0)', 'rgba(113, 240, 34, 1.0)', 'rgba(135, 245, 66, 1.0)'],
        increase: '#1890ff',
        decrease: '#faad14',
        green: 'rgba(135, 245, 66, 1.0)'
  }
};

export const themeColor = handleActions<ThemeState, any>({
  [SET_CUSTOM_THEME]: (state, action: ReturnType<typeof themeActionCreator>) => ({
    themecolor: theme(action.payload.themecolor)
  }),
}, initialState);
