import { cyan, grey } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from "redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import thunk from 'redux-thunk';
import GlobalLoadingModalPage from './components/common/page/GlobalLoadingModalPage';
import Router from './routes/Router';
import reducer from './store/store';
import { GlobalStyle } from './style/globalStyle';
import { useTheme } from './utils/custom-hooks/common/useTheme';
import GlobalAPIInformationModalPage from './components/apicheck/part/ApiInfoPart';

export default function App() {

  const myReducer = reducer();
  const store = createStore(myReducer, applyMiddleware(thunk));
  const persistor = persistStore(store);

  // const customTheme = useTheme();


  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  // const store = createStore(rootReducer);


  const theme = React.useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: "'ChakraPetch-Regular', sans-serif"
        },
      }),
    [prefersDarkMode],
  );

  const newTheme = {
    ...theme,
    headerHeight: '70px',
    drawerWidth: '240px',
  }

  return (
    <>
      {/* <StrictMode> */}
      <ThemeProvider theme={newTheme}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <GlobalStyle />
            <Router />
            <GlobalLoadingModalPage />
          </PersistGate>
        </Provider>
      </ThemeProvider>
      {/* </StrictMode> */}
    </>
  )
}