import { axiosConfigInstance } from '../config';
import { ErrorHandler } from '../errorHandler';
import { setUserInfo } from '../../store/common/user';

export async function apiUserLogin(email: string, password: string, dispatch?: any) {
  const res: any = await axiosConfigInstance('/account').post('', {
    method: 'loginAccount',
    params: {
      user_email: email,
      user_password: password
    }
  }).catch((error: any) => {
    return {
      result: false,
      error_en: 'Please try again later!'
    }
  })

  return res.data;
}

export async function apiUserSignUp(data: any, dispatch?: any) {
  const res = await axiosConfigInstance('/user/signup').post('', {
    params: {
      name: data.name,
      email: data.email,
      password: data.password,
      company: data.company,
      position: data.position
    }
  });
  console.log('apiUserSignUp', res);
  return ErrorHandler(res);
}