import { useState } from "react";
import CommonCodeListPart from "../part/CommonCodeListPart";
import NavigationBarItem from "../../common/item/NavigationBarItem";
import { NAVIGATE_BAR_MENU_ITEMS } from "../../../config/NavigationBarConfig";

export default function CommonCodePage() {
    const [openBar, setOpenBar] = useState(true);

    return (
        <NavigationBarItem pageLocation={'/code'} menuItems={NAVIGATE_BAR_MENU_ITEMS} openBar={openBar} setOpenBar={setOpenBar}>
            <CommonCodeListPart openBar={openBar} />
        </NavigationBarItem>
    )
}