import React from 'react';
import styled from 'styled-components';
import { LightAsync as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BasicText from '../../basic/BasicText';
import { Button } from '@mui/material';
import { CopyAction } from '../../basic/extends/CopyExtends';
import { RowBetween } from '../../../style/basic/commonStyle';

export const H1MarkDown = ({children, ...props}: any) => (
    <Wrap {...props} fontSize={'2.0rem'} bold style={{margin: '30px 0'}}>
        {children}
    </Wrap>
)

export const H2MarkDown = ({children, ...props}: any) => (
    <Wrap {...props} fontSize={'1.7rem'} bold style={{margin: '20px 0 0 0'}}>
        {children}
    </Wrap>
)

export const H3MarkDown = ({children, ...props}: any) => (
    <Wrap {...props} fontSize={'1.5rem'} bold style={{margin: '10px 0 0 0'}}>
        {children}
    </Wrap>
)

export const H4MarkDown = ({children, ...props}: any) => (
    <Wrap {...props} fontSize={'1.3rem'} bold>
        {children}
    </Wrap>
)


export const pMarkDown = ({children, ...props}: any) => (
    <Wrap {...props} fontSize={'1.3rem'}>
        {children}
    </Wrap>
)

export const ulMarkDown = ({children, ...props}: any) => (
    <Wrap {...props} fontSize={'1.5rem'}>
        {children}
    </Wrap>
)

export const liMarkDown = ({children, ...props}: any) => {
    return (
        <Wrap {...props} fontSize={'1.5rem'}>
            {`- ${children}`}
        </Wrap>
    )
}

export const strongMarkDown = ({children, ...props}: any) => (
    <Wrap {...props} fontSize={'1.3rem'} bold>
        {children}
    </Wrap>
)

export const TableMarkDown = ({children, ...props}: any) => (
    <TableWrap {...props} fontSize={'1.3rem'}>
        {children}
    </TableWrap>
)

export const THeadMarkDown = ({children, ...props}: any) => (
    <Wrap {...props} fontSize={'1.5rem'} style={{display: 'flex'}}>
        {children}
    </Wrap>
)

export const TBodyMarkDown = ({children, ...props}: any) => (
    <Wrap {...props} fontSize={'1.5rem'}>
        {children}
    </Wrap>
)

export const aMarkDown = ({children, ...props}: any) => {
    return (
        <ATagWrap {...props} fontSize={'1.3rem'}>
            <a href={props.href} target='_blank'>{children}</a>
        </ATagWrap>
    )
}


export const CodeMarkDown = ({node, inline, className, children, ...props}: any) => {
    const Copy = ({children}: any) => {
        CopyAction(children[0].value);
        copy = true;
    }
    let copy = false;
    const match = /language-(\w+)/.exec(className || '');
    return !inline && match ? (
        <CodeWrap fontSize={'0.9rem'}>
            <HeadWrap>
                <BasicText theme="fixwhite" fontSize={12} fontWeight={'bold'}>{match[1]}</BasicText>
                <Button variant='contained' color='primary' size='small' onClick={() => Copy(node)}>
                    <BasicText theme="fixwhite" fontSize={12}>{copy ? 'copied!' : 'copy'}</BasicText>
                </Button>
            </HeadWrap>
            <SyntaxHighlighter
              children={String(children).replace(/\n$/, '')}
              language={match[1]}
              PreTag="div"
              showLineNumbers
              style={docco}
              lineNumberStyle={{
                color: 'gray',
                fontSize: '0.9rem'
              }}
              customStyle={{borderRadius: 3, maxHeight: 400}}
              {...props}
            />
        </CodeWrap>
      ) : (
        <CodeWrap fontSize={'0.9rem'}>
            <HeadWrap>
                <BasicText theme="fixwhite" fontSize={12} fontWeight={'bold'}>{''}</BasicText>
                <Button variant='contained' color='primary' size='small' onClick={() => Copy(node)}>
                    <BasicText theme="fixwhite" fontSize={12}>{copy ? 'copied!' : 'copy'}</BasicText>
                </Button>
            </HeadWrap>
            <SyntaxHighlighter
              children={String(children).replace(/\n$/, '')}
            //   language={match[1]}
              PreTag="div"
              showLineNumbers
              style={docco}
              lineNumberStyle={{
                color: 'gray',
                fontSize: '1.2rem'
              }}
              customStyle={{borderRadius: 3, maxHeight: 400}}
              {...props}
            />
        </CodeWrap>
      )
}


const Wrap = styled.div<{fontSize?: any, bold?: boolean}>`
    font-weight: ${props => props.bold ? 'bold' : 500};
    font-size: ${props => props.fontSize ?? '1.5rem'};
`;

const CodeWrap = styled.div<{fontSize?: any}>`
    font-size: ${props => props.fontSize ?? '1.5rem'};
    font-weight: 500;
    background-color: #000;
    padding: 2px;
    border-radius: 5px;
`;

const TableWrap = styled.div<{fontSize?: any}>`
    font-size: ${props => props.fontSize ?? '1.5rem'};
    text-align: center;
    margin: 10px 10px;
    width: 100%;
    padding: 10px;

    thead {
        border: 0.1px solid lightgray;
        min-width: 100%;
        padding: 10px;
        font-weight: bold;
    }

    tbody {
        border: 0.1px solid lightgray;
    }

    tr {
        padding: 0 10px;
        border: 0.1px solid lightgray;
    }

    td {
        background-color: #FFF;
        padding: 0 10px;
        border: 0.1px solid lightgray;
        border-radius: 5px;
    }

`;

const HeadWrap = styled(RowBetween)`
    width: 100%;
    padding: 10px;
`;

const ATagWrap = styled.div<{fontSize?: any, bold?: boolean}>`
    font-weight: ${props => props.bold ? 'bold' : 500};
    font-size: ${props => props.fontSize ?? '1.5rem'};
    cursor: pointer;
    transition: 0.3s;
    color: blue;
    text-decoration: underline;
    width: fit-content;

    &:hover {
        color: blue;
        font-weight: bold;
        padding: 3px;
    }
`;
