import { useState } from "react";
import { NAVIGATE_BAR_MENU_ITEMS } from "../../../config/NavigationBarConfig";
import NavigationBarItem from "../../common/item/NavigationBarItem";
import CommonCodeUploadPart from "../part/CommonCodeUploadPart";

export default function UploadCommonCodePage() {
    const [openBar, setOpenBar] = useState(true);

    return (
        <NavigationBarItem pageLocation={'/code/upload'} menuItems={NAVIGATE_BAR_MENU_ITEMS} openBar={openBar} setOpenBar={setOpenBar}>
            <CommonCodeUploadPart />
        </NavigationBarItem>
    )
}