import styled from "@emotion/styled"
import { Paper } from "@mui/material"
import BasicText from "../../basic/BasicText"
import TitleWithTextFieldItem from "../../common/item/TitleWithTextFieldItem"
import BasicButton from "../../basic/BasicButton"
import { useState } from "react"
import { apiGetProjectServerInfo } from "../../../api/project/project_info_api"
import { userUserAuthPayload } from "../../../utils/custom-hooks/common/userUserAuthPayload"

export default function ServerEditPart({ projectId }: any) {
    const authPayload = userUserAuthPayload();

    const [cryptoKey, setCryptoKey] = useState('');
    const [serverInfo, setServerInfo] = useState<any>();

    function getServerInfoHandler() {
        apiGetProjectServerInfo(projectId, cryptoKey, authPayload).then((res: any) => {
            if (res.result) {
                setServerInfo(res.data);
            } else {
                alert(res.error_en);
            }
        })
    }

    return (
        <PartWrap>
            {!serverInfo && (
                <>
                    <BasicText marginBottom={1}>PLEASE ENTER YOUR CRYPTO KEY TO VIEW SERVER INFO OF THIS PROJECT</BasicText>
                    <CryptoKeyInputWrap>
                        <TitleWithTextFieldItem value={cryptoKey} onChange={(value: string) => setCryptoKey(value)} inputSize="small" style={{ width: '50%', marginRight: '10px' }} />
                        <BasicButton onPress={getServerInfoHandler} size="small">
                            <BasicText>ENTER</BasicText>
                        </BasicButton>
                    </CryptoKeyInputWrap>
                </>
            )}
            {serverInfo && (
                <>
                    <BasicText fontSize={24} fontWeight={700} marginBottom={4}>SERVER INFO</BasicText>
                    <TitleWithTextFieldItem sx={{ marginBottom: '10px' }} title='BASIC URL' disabled showTextLength={false} value={serverInfo.project_baseURL} />
                    <TitleWithTextFieldItem sx={{ marginBottom: '10px' }} title='PORT NUMBER' disabled showTextLength={false} value={serverInfo.project_api_port} />
                    <TitleWithTextFieldItem sx={{ marginBottom: '10px' }} title='HOST' disabled showTextLength={false} value={serverInfo.project_api_host} />
                    <TitleWithTextFieldItem sx={{ marginBottom: '10px' }} title='DB PORT' disabled showTextLength={false} value={serverInfo.project_db_port} />
                    <TitleWithTextFieldItem sx={{ marginBottom: '10px' }} title='DB USER' disabled showTextLength={false} value={serverInfo.project_db_user} />
                    <TitleWithTextFieldItem sx={{ marginBottom: '10px' }} title='DB NAME' disabled showTextLength={false} value={serverInfo.project_db_name} />
                    <TitleWithTextFieldItem sx={{ marginBottom: '30px' }} title='JWT SECRET KEY' disabled showTextLength={false} value={serverInfo.project_jwt_key} />
                    <EditButtonWrap>
                        <BasicButton>
                            <BasicText>EDIT</BasicText>
                        </BasicButton>
                    </EditButtonWrap>
                </>
            )}
        </PartWrap>
    )
}

const PartWrap = styled(Paper)`
padding: 30px;
`

const CryptoKeyInputWrap = styled.div`
display: flex;
align-items: center;
`

const EditButtonWrap = styled.div``