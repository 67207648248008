import styled from "styled-components";
import BasicText from "../../basic/BasicText";
import { useState } from "react";
import BasicInfoPart from "./BasicInfoPart";
import PlatformInfoPart from "./PlatformInfoPart";
import ServerInfoPart from "./ServerInfoPart";
import { apiRegisterPlatforms, apiRegisterProject, apiRegisterProjectConfig } from "../../../api/project/project_api_api";
import { userUserAuthPayload } from "../../../utils/custom-hooks/common/userUserAuthPayload";
import { useNavigate } from "react-router";

export default function UploadProjectPart() {
    const authPayload = userUserAuthPayload();
    const navigate = useNavigate();

    const [basicInfo, setBasicInfo] = useState<any>({
        project_type: '1',
    });
    const [platformInfo, setPlatformInfo] = useState<any>([]);
    const [serverInfo, setServerInfo] = useState({});

    const [projectId, setProjectId] = useState<number>();
    const [uploadPage, setUploadPage] = useState<number>(1);

    function setBasicInfoHandler(key: string, value: any) { // 기본정보 수정시 입력값 실시간으로 저장
        setBasicInfo((prev: any) => {
            const newInfo = { ...prev };
            newInfo[key] = value;

            return newInfo;
        });
    }

    function submitBasicInfoHandler() { // 기본정보 수정 시 다음 정보 수정 하게끔 상태값 변경
        apiRegisterProject(basicInfo, authPayload).then((res: any) => {
            if (res.result) {
                setProjectId(res.data.project_id);
                setUploadPage(2);
            } else {
                alert(res.error_en);
            }
        })
    }

    function setPlatformInfoHandler(key: string, value: any, index: number) { // 플랫폼 정보 입력시 실시간으로 값 저장
        setPlatformInfo((prev: any) => {
            const newInfo = [...prev];
            if (key === 'project_platform') {
                if (value === 'add') newInfo.push({ platform_id: newInfo.length + 1, platform_type: '1' })
                else newInfo.pop();

                return newInfo
            } else {
                newInfo[index][key] = value;

                return newInfo;
            }
        })
    }

    function submitPlatformInfoHandler() { // 플랫폼 정보 수정시 다음 정보 수정 하게끔 상태값 변경
        apiRegisterPlatforms(projectId as number, platformInfo, authPayload).then((res: any) => {
            if (res.result) {
                setUploadPage(3);
            } else {
                alert(res.error_en);
            }
        })
    }

    function setServerInfoHandler(key: string, value: any) { // 서버 정보 입력시 실시간으로 값 저장
        setServerInfo((prev: any) => {
            const newInfo = { ...prev };
            newInfo[key] = value;

            return newInfo;
        });
    }

    function submitServerInfoHandler() { // 마지막 서버 정보 수정시 입력된 정보를 모아 최종 제출하는 api 호출
        apiRegisterProjectConfig(projectId as number, serverInfo, authPayload).then((res: any) => {
            if (res.result) {
                alert('Project uploaded successfully!');
                navigate('/project');
            } else {
                alert(res.error_en);
            }
        })
    }

    return (
        <PartWrap>
            <PartHeaderWrap>
                <BasicText fontSize={30} fontWeight={700}>UPLOAD PROJECT</BasicText>
                <BasicText fontSize={30} fontWeight={700}>{uploadPage} / 3</BasicText>
            </PartHeaderWrap>
            {uploadPage === 1 && <BasicInfoPart info={basicInfo} onInputChange={setBasicInfoHandler} onSubmit={submitBasicInfoHandler} />}
            {uploadPage === 2 && <PlatformInfoPart info={platformInfo} onInputChange={setPlatformInfoHandler} setUploadPage={setUploadPage} onSubmit={submitPlatformInfoHandler} />}
            {uploadPage === 3 && <ServerInfoPart info={serverInfo} onInputChange={setServerInfoHandler} setUploadPage={setUploadPage} onSubmit={submitServerInfoHandler} />}
        </PartWrap>
    )
}

const PartWrap = styled.div`
width: 100%;
padding: 30px;
`

const PartHeaderWrap = styled.div`
display: flex;
justify-content: space-between;
margin-bottom: 30px;
`