// --------------------- 프로젝트 권한 ----------------------------

export const LEVEL_GET_PROJECT_SUMMARY_INFO_LIST = 5; // 프로젝트 정보 가져오기
export const LEVEL_GET_PROJECT_DETAIL_INFO = 5; // 프로젝트의 자세한 정보 보기

// --------------------- API 권한 ----------------------------

export const LEVEL_GET_API_DETAIL_INFO = 4; // API 상세 정보 가져오기
export const LEVEL_REGISTER_PROJECT_API = 3; // 프로젝트 API 등록하기
export const LEVEL_EDIT_PROJECT_API = 2; // 프로젝트 API 수정하기
export const LEVEL_CONTROL_VISIBLE_API = 2; // 프로젝트 API 숨김/숨김 해제하기

// --------------------- FTP 권한 ----------------------------

export const LEVEL_REGISTER_FTP_INFO = 2; // FTP 정보 등록하기
export const LEVEL_GET_FTP_INFO_LIST = 2; // FTP 리스트 가져오기
export const LEVEL_GET_FTP_INFO = 2; // FTP 상세 정보 가져오기