import { useEffect, useState } from 'react';
import EditApiPart from '../part/EditApiPart';
import NavigationBarItem from '../../common/item/NavigationBarItem';
import { NAVIGATE_BAR_MENU_ITEMS } from '../../../config/NavigationBarConfig';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';
import { useNavigate } from 'react-router';
import { LEVEL_EDIT_PROJECT_API } from '../../../config/permissionConfig';

export default function EditApiPage() {
    const [openBar, setOpenBar] = useState(true);
    const userInfo = useUserInfo();
    const navigate = useNavigate();

    useEffect(() => {
        if (userInfo.user_id === '') {
            alert('로그인 후 이용해주세요!')
            navigate('/login');
        }

        if (userInfo.user_level > LEVEL_EDIT_PROJECT_API) {
            alert('권한이 없습니다.');
            navigate(-1);
        }
    }, [])

    return (
        <NavigationBarItem pageLocation={'/project'} menuItems={NAVIGATE_BAR_MENU_ITEMS} openBar={openBar} setOpenBar={setOpenBar}>
            <EditApiPart />
        </NavigationBarItem>
    )
}
