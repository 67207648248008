import { useEffect, useState } from 'react';
import styled from 'styled-components';
import BasicText from '../../basic/BasicText';
import { Paper } from '@mui/material';
import { useParams } from 'react-router';
import { apiGetProjectDetailInfo, apiModifyProjectBasicInfo } from '../../../api/project/project_info_api';
import { apiGetRegisterSummaryBaseData } from '../../../api/project/project_info_api';
import { useDispatch } from 'react-redux';
import { userUserAuthPayload } from '../../../utils/custom-hooks/common/userUserAuthPayload';
import BasicButton from '../../basic/BasicButton';
import TitleWithTextFieldItem from '../../common/item/TitleWithTextFieldItem';
import TitleWithSelectItem from '../../common/item/TitleWithSelectItem';
import TitleWithDropzoneItem from '../../common/item/TitleWithDropzoneItem';
import ServerEditPart from './ServerEditPart';

export default function ProjectInfoPart() {
    const [projectInfo, setProjectInfo] = useState<any>();
    const [serverInfo, setServerInfo] = useState<any>();
    const dispatch = useDispatch();
    const authPayload = userUserAuthPayload();

    const { project_id } = useParams();

    useEffect(() => {
        apiGetProjectDetailInfo(project_id, authPayload, dispatch).then((res: any) => {
            if (res.result) {
                setProjectInfo(res.data);
            }
        })

        apiGetRegisterSummaryBaseData(project_id, authPayload).then((res: any) => {
            if (res.result) {
                setServerInfo(res.data);
            }
        })
    }, [])

    function editProjectNameHandler(key: string, value: string) {
        setProjectInfo((prev: any) => {
            const newInfo = { ...prev };

            newInfo[key] = value;

            return newInfo;
        })
    }

    function editBasicInfoHandler() {
        apiModifyProjectBasicInfo(projectInfo, authPayload).then((res: any) => {
            if (res.result) {
                alert('수정이 완료되었습니다.')
            } else {
                alert(res.error_en);
            }
        })
    }

    return (
        <PartWrap>
            <PartHeaderWrap>
                <BasicText fontSize={30} fontWeight={700}>PROJECT DETAIL</BasicText>
            </PartHeaderWrap>
            {projectInfo && (
                <>
                    <BasicInfoWrap>
                        <BasicText fontSize={24} fontWeight={700} marginBottom={4}>BASIC INFO</BasicText>
                        <TitleWithTextFieldItem onChange={(value: string) => { editProjectNameHandler('project_name', value); }} sx={{ marginBottom: '10px' }} title='PROJECT NAME' showTextLength={false} value={projectInfo.project_name} />
                        <TitleWithTextFieldItem onChange={(value: string) => { editProjectNameHandler('project_description', value); }} sx={{ marginBottom: '10px' }} title='PROJECT PROJECT DESCRIPTION' showTextLength={false} value={projectInfo.project_description} />
                        <TitleWithDropzoneItem title='PROJECT ICON' value={projectInfo.project_icon ? projectInfo.project_icon : undefined} onDrop={(value: string) => { editProjectNameHandler('project_icon', value); }} style={{ marginBottom: '10px', width: '200px', height: '200px' }} />
                        <TitleWithSelectItem sx={{ marginBottom: '30px' }} onChange={(value: string) => { editProjectNameHandler('project_type', value); }} title='PROJECT TYPE' value={projectInfo.project_type} selectItems={[{ value: '1', title: 'PLATFORM' }, { value: '2', title: 'MTC' }, { value: '3', title: 'SERVER' }, { value: '4', title: 'WORKSPACE' }]} />
                        <EditButtonWrap>
                            <BasicButton onPress={editBasicInfoHandler}>
                                <BasicText>EDIT</BasicText>
                            </BasicButton>
                        </EditButtonWrap>
                    </BasicInfoWrap>
                    <PlatformInfoGroupWrap>
                        <BasicText fontSize={24} fontWeight={700} marginBottom={4}>PLATFORM INFO</BasicText>
                        {/* 플랫폼 정보는 여러개가 될 수 있기 때문에 배열형식으로 렌더링 */}
                        {projectInfo.project_platform.map((item: any) => (
                            <PlatformInfoWrap key={item.platform_id} sx={{ backgroundColor: 'rgb(245, 245, 245)' }}>
                                <TitleWithSelectItem sx={{ marginBottom: '10px' }} title='PLATFORM TYPE' disabled value={item.platform_type} selectItems={[{ value: 1, title: 'SINGLE PAGE' }, { value: 2, title: 'App(Android)' }, { value: 3, title: 'App(IOS)' }, { value: 4, title: 'Web' }, { value: 5, title: 'Admin' }, { value: 6, title: 'API Server' }]} />
                                <TitleWithTextFieldItem sx={{ marginBottom: '10px' }} title='PLATFORM VERSION' disabled showTextLength={false} value={item.platform_version} />
                                <TitleWithTextFieldItem sx={{ marginBottom: '10px' }} title='PLATFORM PACKAGE NAME' disabled showTextLength={false} value={item.platform_package_name} />
                                <TitleWithTextFieldItem sx={{ marginBottom: '10px' }} title='PLATFORM DEV STACK' disabled showTextLength={false} value={item.platform_dev_stack} />
                                <TitleWithTextFieldItem title='PLATFORM LINK' disabled showTextLength={false} value={item.platform_link} />
                            </PlatformInfoWrap>
                        ))}
                        <EditButtonWrap>
                            <BasicButton>
                                <BasicText>EDIT</BasicText>
                            </BasicButton>
                        </EditButtonWrap>
                    </PlatformInfoGroupWrap>
                </>
            )}
            {serverInfo && (
                <ServerEditPart projectId={project_id} />
            )}
        </PartWrap>
    )
}

const PartWrap = styled.div`
padding: 30px;
`

const PartHeaderWrap = styled.div`
margin-bottom: 30px;
`

const BasicInfoWrap = styled(Paper)`
padding: 30px;
margin-bottom: 30px;
`

const PlatformInfoGroupWrap = styled(Paper)`
padding: 30px;
margin-bottom: 30px;
`

const PlatformInfoWrap = styled(Paper)`
padding: 30px;
margin-bottom: 30px;
`

const EditButtonWrap = styled.div``