import { TextField } from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';

interface BasicInputProps {
    fullwidth?: boolean;
    size?: any;
    id?: string;
    key?: string;
    onValue?: any;
    multiline?: boolean;
    minRows?: number;
    maxRows?: number;
    defaultValue?: string;
    [x: string]: any;
}

export default function BasicInput({ fullwidth = false, size = 'small', id, key, multiline = false, minRows = 1, maxRows = 1, onValue, defaultValue = '', ...rest }: BasicInputProps) {

    const [value, setValue] = useState(defaultValue);

    const onChangeText = (e: any) => {
        setValue(e.target.value);
        onValue(e.target.value);
    }

    return (
        <TextInput
            size={size}
            fullWidth={fullwidth}
            id={id}
            key={key}
            value={value}
            autoComplete='off'
            multiline={multiline}
            minRows={minRows}
            maxRows={maxRows}
            onChange={(e) => onChangeText(e)}
            {...rest}
        />
    )
}

const TextInput = styled(TextField)`
    font-size: 13px;
`;