import styled from "@emotion/styled";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import BasicText from "../../basic/BasicText";
import { CSSProperties } from "react";

interface TitleWithSelectItemType {
    value?: any;
    style?: CSSProperties;
    isRequired?: boolean;
    title?: string;
    titleFontSize?: number;
    inputSize?: "small" | "medium";
    label?: string;
    width?: string;
    selectItems?: any;
    onChange?: any;
    backgroundColor?: string;
    borderRadius?: string;
    border?: string;
    hoverBorder?: string;
    focusBorder?: string;
    [x: string]: any;
}

export default function TitleWithSelectItem({ value = 1, style = undefined, isRequired = false, title = undefined, titleFontSize = 16, inputSize = "medium", label = undefined, width = '100%', selectItems = [{ value: 1, title: 'a' }, { value: 2, title: 'b' }], onChange = () => { }, backgroundColor = 'white', borderRadius = '10px', border = '1px solid black', hoverBorder = '1px solid black', focusBorder = '1px solid black', ...rest }: TitleWithSelectItemType) {
    function valueChangeHandler(e: any) {
        onChange(e.target.value);
    }

    let restWithoutSx: any = {};

    if (rest.sx && Object.keys(rest).length !== 0) {
        Object.keys(rest).forEach((key: string) => {
            if (key !== 'sx') restWithoutSx[key] = rest[key];
        })
    }

    return (
        <ItemWrap style={{ ...style }}>
            {title && (
                <ItemTitleWrap>
                    <BasicText fontSize={titleFontSize}>{title}{isRequired && <RequiredInput>*</RequiredInput>}</BasicText>
                </ItemTitleWrap>
            )}
            <FormControl style={{ width, marginTop: title && '5px' }}>
                <InputLabel>{label}</InputLabel>
                <Select
                    value={value}
                    onChange={valueChangeHandler}
                    label={label}
                    size={inputSize}
                    sx={{
                        borderRadius,
                        "fieldset": {
                            border,
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: focusBorder,
                        },
                        "&: hover": {
                            "&& fieldset": {
                                border: hoverBorder,
                            }
                        },
                        ...rest.sx,
                    }}
                    {...restWithoutSx}
                >
                    {selectItems.map((item: any, index: any) => {
                        return <MenuItem key={`${Math.random() * index}`} value={item.value}>{item.title}</MenuItem>
                    })}
                </Select>
            </FormControl>
        </ItemWrap>
    )
}

const ItemWrap = styled.div``

const ItemTitleWrap = styled.div`
padding-bottom: 5px;
`

const RequiredInput = styled.span`
color: red;
`