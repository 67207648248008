import styled from "styled-components";
import BasicButton from "../../basic/BasicButton";
import BasicText from "../../basic/BasicText";
import TitleWithTextFieldItem from "../../common/item/TitleWithTextFieldItem";
import TitleWithSelectItem from "../../common/item/TitleWithSelectItem";

export default function PlatformPart({ info, onInputChange, setUploadPage, onSubmit }: any) {
    return (
        <InputBoxWrap>
            <AddPlatformWrap>
                <BasicButton sx={{ marginRight: '10px' }} onPress={() => onInputChange('project_platform', 'add')}>
                    <BasicText color={'white'}>+</BasicText>
                </BasicButton>
                <BasicButton onPress={() => onInputChange('project_platform', 'remove')}>
                    <BasicText color={'white'}>-</BasicText>
                </BasicButton>
            </AddPlatformWrap>
            {info.map((item: any, index: number) => (
                <div key={item.platform_id}>
                    <FTPInputWrap>
                        <InputTitleWrap>
                            <BasicText>PLATFORM TYPE :{'\u00A0'}</BasicText>
                        </InputTitleWrap>
                        <TitleWithSelectItem value={item.platform_type} onChange={(value: string) => { onInputChange('platform_type', value, index) }} selectItems={[{ value: '1', title: 'SINGLE PAGE' }, { value: '2', title: 'APP(Android)' }, { value: '3', title: 'APP(IOS)' }, { value: '4', title: 'WEB' }, { value: '5', title: 'ADMIN' }, { value: '6', title: 'SERVER' }]} />
                    </FTPInputWrap>
                    <FTPInputWrap>
                        <InputTitleWrap>
                            <BasicText>PLATFORM VERSION :{'\u00A0'}</BasicText>
                        </InputTitleWrap>
                        <TitleWithTextFieldItem style={{ flexGrow: 1 }} value={item.platform_version} onChange={(value: string) => { onInputChange('platform_version', value, index) }} />
                    </FTPInputWrap>
                    <FTPInputWrap>
                        <InputTitleWrap>
                            <BasicText>PLATFORM PACKAGE NAME :{'\u00A0'}</BasicText>
                        </InputTitleWrap>
                        <TitleWithTextFieldItem style={{ flexGrow: 1 }} value={item.platform_package_name} onChange={(value: string) => { onInputChange('platform_package_name', value, index) }} />
                    </FTPInputWrap>
                    <FTPInputWrap>
                        <InputTitleWrap>
                            <BasicText>PLATFORM DEV STACK :{'\u00A0'}</BasicText>
                        </InputTitleWrap>
                        <TitleWithTextFieldItem style={{ flexGrow: 1 }} value={item.platform_dev_stack} onChange={(value: string) => { onInputChange('platform_dev_stack', value, index) }} />
                    </FTPInputWrap>
                    <FTPInputWrap>
                        <InputTitleWrap>
                            <BasicText>PLATFORM LINK :{'\u00A0'}</BasicText>
                        </InputTitleWrap>
                        <TitleWithTextFieldItem style={{ flexGrow: 1 }} value={item.platform_link} onChange={(value: string) => { onInputChange('platform_link', value, index) }} />
                    </FTPInputWrap>
                </div>
            ))}
            <InputMoveWrap>
                <BasicButton onPress={() => { setUploadPage(1) }}>
                    <BasicText color={'white'}>BEFORE</BasicText>
                </BasicButton>
                <BasicButton onPress={() => { onSubmit() }}>
                    <BasicText color={'white'}>NEXT</BasicText>
                </BasicButton>
            </InputMoveWrap>
        </InputBoxWrap>
    )
}

const InputBoxWrap = styled.div`
width: 50%;
`

const AddPlatformWrap = styled.div`
display: flex;
margin-bottom: 10px;
`

const FTPInputWrap = styled.div`
display: flex;
align-items: center;
margin-bottom: 10px;
`

const InputTitleWrap = styled.div`
width: 40%;
`

const InputMoveWrap = styled.div`
display: flex;
justify-content: space-between;
`