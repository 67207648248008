import axios from 'axios';

export interface HeaderPayloadType {
    access_token: string;
    authorization: string;
}

export const axiosConfigInstance = (path: string, header_payload?: HeaderPayloadType) =>  axios.create({
    baseURL: `https://deva.subox.co.kr${path}`,
    // baseURL: `http://192.168.1.21:10000${path}`,
    timeout: 20000,
    headers: {
        'access-token': header_payload?.access_token ?? '',
        'authorization': header_payload?.authorization ?? ''
    }
});
