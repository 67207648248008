import { useTheme } from '@mui/material/styles';
import styled from '@emotion/styled';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import BasicText from '../../basic/BasicText';
import BasicButton from '../../basic/BasicButton';
import { useState } from "react";
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { setUserInfoLogOutActionCreator } from '../../../store/common/user';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';

interface DrawerCategoryItemType {
    menuData: MenuItemType;
    currentPage: string;
}

function DrawerCategoryItem({ menuData, currentPage }: DrawerCategoryItemType) {
    const [open, setOpen] = useState(currentPage.includes(menuData.pageLocation));
    const theme = useTheme();
    const navigate = useNavigate();

    return (
        <List>
            <ListItemButton onClick={() => {
                if (menuData.collapseMenu) setOpen((prev: boolean) => !prev);
                else navigate(`${menuData.pageLocation}`);
            }} sx={{ borderRadius: '10px', pl: menuData.titleIcon ? 0 : 4 }} selected={currentPage.includes(menuData.pageLocation)}>
                {menuData.titleIcon && (
                    <ListItemIcon sx={{ color: 'black' }}>
                        {menuData.titleIcon}
                    </ListItemIcon>
                )}
                <ListItemText style={{ textTransform: 'none' }}>
                    <BasicText fontSize={16}>{menuData.titleCategory}</BasicText>
                </ListItemText>
                {menuData.collapseMenu && (
                    open ? <ExpandLessIcon /> : <ExpandMoreIcon />
                )}
            </ListItemButton>
            {menuData.collapseMenu && menuData.collapseMenu.map((item: CollapseMenuType) => (
                <Collapse onClick={() => { navigate(`${item.pageLocation}`); }} key={item.collapseMenuId} in={open} timeout="auto" unmountOnExit sx={{ backgroundColor: item.pageLocation === currentPage ? theme.palette.primary.main : 'rgb(240,240,240)', borderRadius: '10px' }}>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: menuData.titleIcon ? 7 : 4, borderRadius: '10px' }}>
                            <ListItemText>
                                <BasicText fontSize={14} color={item.pageLocation === currentPage ? 'white' : 'black'}>{item.title}</BasicText>
                            </ListItemText>
                        </ListItemButton>
                    </List>
                </Collapse>
            ))}
        </List>
    )
}

interface CollapseMenuType {
    collapseMenuId: number,
    title: string,
    pageLocation: string
}

interface MenuItemType {
    menuId: number,
    pageLocation: string,
    titleIcon?: JSX.Element,
    titleCategory: string,
    collapseMenu?: CollapseMenuType[]
}

interface NavigationBarItemProps {
    pageLocation: string,
    children: JSX.Element,
    menuItems: MenuItemType[],
    openBar: boolean,
    setOpenBar: any,
}

export default function NavigationBarItem({ pageLocation, children, menuItems, openBar, setOpenBar }: NavigationBarItemProps) {
    // pageLocation prop을 통해 현재 속해있는 메뉴의 배경색을 바꿔줌
    const theme = useTheme<any>();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userInfo = useUserInfo();

    const showDrawerHandler = () => {
        setOpenBar((prev: boolean) => !prev);
    };

    function logoutHandler() {
        dispatch(setUserInfoLogOutActionCreator());
        navigate('/login');
    }

    return (
        <ItemWrap>
            <NavigationBar position="fixed" open={openBar}>
                <BarLeftWrap>
                    <BarLogoWrap>
                        <IconButton
                            onClick={showDrawerHandler}
                            sx={{ mr: 1 }}
                        >
                            <MenuIcon sx={{ color: theme.palette.primary.main }} />
                        </IconButton>
                    </BarLogoWrap>
                </BarLeftWrap>
                <BarRightWrap>
                    <LoginInfoWrap>
                        <BasicText color={'black'}>안녕하세요. 보안등급은 {userInfo.user_level}등급입니다.</BasicText>
                        <BasicButton onPress={logoutHandler} color='secondary' sx={{ borderRadius: '1000px', marginLeft: '10px' }}>
                            <BasicText fontSize={16} color={'white'} fontWeight={500}>Logout</BasicText>
                        </BasicButton>
                    </LoginInfoWrap>
                </BarRightWrap>
            </NavigationBar>
            <Drawer
                sx={{
                    width: theme.drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: theme.drawerWidth,
                        boxSizing: 'border-box',
                        backgroundColor: 'rgb(244, 247, 253)',
                        borderRight: 'none',
                        padding: '10px',
                        marginTop: theme.headerHeight,
                    },
                }}
                variant="persistent"
                anchor="left"
                open={openBar}
            >
                {/* 메뉴 바, 메뉴 바 색깔 표시 */}
                {menuItems.map((item: MenuItemType) => {
                    // 각 메뉴 아이템, 메뉴의 하위항목이 속해있을 경우 menuData prop으로 내려줌
                    return (
                        <DrawerCategoryItem
                            key={item.menuId}
                            menuData={item}
                            currentPage={pageLocation}
                        />
                    )
                })}
            </Drawer>
            <ContentWrap open={openBar}>
                {children}
            </ContentWrap>
        </ItemWrap>
    )
}

const ItemWrap = styled.div`
display: flex;
`

const NavigationBar = styled(MuiAppBar) <{ open: boolean }>`
width: 100%;
height: ${(props: any) => props.theme.headerHeight};
background-color: white;
padding-left: 20px;
padding-right: 20px;
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
box-shadow: none;
transition: ${(props: any) => props.theme.transitions.create(['margin', 'width'], {
    easing: props.theme.transitions.easing.sharp,
    duration: props.theme.transitions.duration.leavingScreen,
})};
${(props: any) => {
    // LeftNavBar가 줄어들고 늘어날 때마다 width 값을 변경
        return props.open && {
            marginLeft: props.theme.drawerWidth,
            transition: props.theme.transitions.create(['margin', 'width'], {
                easing: props.theme.transitions.easing.easeOut,
                duration: props.theme.transitions.duration.enteringScreen,
            })
        }
    }};
`

const BarLeftWrap = styled.div`
display: flex;
`

const BarLogoWrap = styled.div`
display: flex;
align-items: center;
`

const BarRightWrap = styled.div``

const LoginInfoWrap = styled.div`
display: flex;
align-items: center;
`

const ContentWrap = styled.div<{ open: boolean }>`
flex-grow: 1;
padding: ${(props: any) => props.theme.spacing(0)};
padding-top: ${(props: any) => props.theme.headerHeight};
margin-left: -${(props: any) => props.theme.drawerWidth};
transition: ${(props: any) => props.theme.transitions.create('margin', {
    easing: props.theme.transitions.easing.sharp,
    duration: props.theme.transitions.duration.leavingScreen,
})};
${(props: any) => {
        return props.open && {
            transition: props.theme.transitions.create('margin', {
                easing: props.theme.transitions.easing.easeOut,
                duration: props.theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }
    }};
`