export const NAVIGATE_BAR_MENU_ITEMS = [{
    menuId: 1,
    pageLocation: '/project',
    titleCategory: '모든 프로젝트',
}, {
    menuId: 2,
    pageLocation: '/ftp',
    titleCategory: 'FTP',
}, {
    menuId: 3,
    pageLocation: '/code',
    titleCategory: '공용 코드',
}, {
    menuId: 4,
    pageLocation: '/board',
    titleCategory: '개발자 보드',
}, {
    menuId: 5,
    pageLocation: '/setting',
    titleIcon: <></>,
    titleCategory: '설정',
    collapseMenu: [
        { collapseMenuId: 1, title: '유저', pageLocation: '/setting/user' },
    ]
}]