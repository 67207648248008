import { TextField } from "@mui/material";
import { CSSProperties } from "react";
import styled from "@emotion/styled";
import BasicText from "../../basic/BasicText";

interface TitleWithTextFieldItemType {
    value?: string;
    style?: CSSProperties;
    showTextLength?: boolean;
    isRequired?: boolean;
    title?: string;
    titleFontSize?: number;
    inputSize?: "small" | "medium";
    label?: string;
    placeholder?: string;
    width?: string;
    onChange?: any;
    maxTextLength?: number;
    backgroundColor?: string;
    border?: string;
    hoverBorder?: string;
    focusBorder?: string;
    borderRadius?: string;
    sx?: any;
    [x: string]: any;
}

export default function TitleWithTextFieldItem({ value = '', style = undefined, showTextLength = false, isRequired = false, title = undefined, titleFontSize = 16, inputSize = "medium", label = undefined, placeholder = undefined, width = '100%', onChange = undefined, maxTextLength = undefined, backgroundColor = 'white', border = '1px solid black', hoverBorder = '1px solid black', focusBorder = '1px solid black', borderRadius = '10px', sx = undefined, ...rest }: TitleWithTextFieldItemType) {
    function valueChangeHandler(e: any) {
        onChange && onChange(e.target.value);
    }

    return (
        <ItemWrap style={{ ...style }}>
            {title && (
                <ItemTitleWrap>
                    <BasicText fontSize={titleFontSize}>{title}{isRequired && <RequiredInput>*</RequiredInput>}</BasicText>
                    {showTextLength && <BasicText fontSize={titleFontSize}>{value.length}/{maxTextLength}</BasicText>}
                </ItemTitleWrap>
            )}
            <TextField size={inputSize} value={value} sx={{
                width,
                marginTop: title && '5px',
                '&': {
                    backgroundColor,
                    borderRadius,
                },
                '& fieldset': {
                    border,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    borderRadius,
                },
                '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                        border: hoverBorder,
                    },
                    '&.Mui-focused fieldset': {
                        border: focusBorder,
                    },
                },
                ...sx,
            }} label={label} variant="outlined" placeholder={placeholder} onChange={valueChangeHandler} inputProps={{ maxLength: (maxTextLength as number - 1).toString() }} {...rest} />
        </ItemWrap>
    )
}

const ItemWrap = styled.div``

const ItemTitleWrap = styled.div`
display: flex;
justify-content: space-between;
padding-bottom: 5px;
`

const RequiredInput = styled.span`
color: red;
`