import styled from "@emotion/styled"
import BasicText from "../../basic/BasicText"
import BasicButton from "../../basic/BasicButton"

export default function BoardUploadPart() {
    return (
        <PartWrap>
            <PartHeaderWrap>
                <BasicText fontSize={30} fontWeight={700}>UPLOAD BOARD</BasicText>
            </PartHeaderWrap>
            <PartContentWrap>

            </PartContentWrap>
        </PartWrap>
    )
}

const PartWrap = styled.div`
padding: 30px;
`

const PartHeaderWrap = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
margin-bottom: 30px;
`

const PartContentWrap = styled.div``