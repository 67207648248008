import BasicButton from "../../basic/BasicButton";
import BasicText from "../../basic/BasicText";
import styled from "styled-components";
import TitleWithTextFieldItem from "../../common/item/TitleWithTextFieldItem";
import TitleWithSelectItem from "../../common/item/TitleWithSelectItem";
import TitleWithDropzoneItem from "../../common/item/TitleWithDropzoneItem";

export default function BasicInfoPart({ info, onInputChange, onSubmit }: any) {
    const nextAction = () =>{
        if(info.project_name === '' || info.project_name === undefined) return;
        if(info.project_icon === '' || info.project_icon === undefined) return;
        if(info.project_description === '' || info.project_description === undefined) return;
        if(info.project_type === '' || info.project_type === undefined) return;
        onSubmit();
    }

    return (
        <InputBoxWrap>
            <FTPInputWrap>
                <InputTitleWrap>
                    <BasicText>PROJECT NAME :{'\u00A0'}</BasicText>
                </InputTitleWrap>
                <TitleWithTextFieldItem value={info.project_name} style={{ flexGrow: 1 }} onChange={(value: string) => { onInputChange('project_name', value) }} />
            </FTPInputWrap>
            <FTPInputWrap>
                <InputTitleWrap>
                    <BasicText>PROJECT LOGO :{'\u00A0'}</BasicText>
                </InputTitleWrap>
                <TitleWithDropzoneItem value={info.project_icon} style={{ width: '300px', height: '300px' }} onDrop={(file: any) => { onInputChange('project_icon', file); }} />
            </FTPInputWrap>
            <FTPInputWrap>
                <InputTitleWrap>
                    <BasicText>PROJECT DESCRIPTION :{'\u00A0'}</BasicText>
                </InputTitleWrap>
                <TitleWithTextFieldItem value={info.project_description} style={{ flexGrow: 1 }} onChange={(value: string) => { onInputChange('project_description', value) }} />
            </FTPInputWrap>
            <FTPInputWrap>
                <InputTitleWrap>
                    <BasicText>PROJECT TYPE :{'\u00A0'}</BasicText>
                </InputTitleWrap>
                <TitleWithSelectItem value={info.project_type} onChange={(value: string) => { onInputChange('project_type', value) }} selectItems={[{ value: '1', title: 'PLATFORM' }, { value: '2', title: 'MTC' }, { value: '3', title: 'SERVER' }, { value: '4', title: 'WORKSPACE' }]} />
            </FTPInputWrap>
            <BasicButton onPress={nextAction}>
                <BasicText color={'white'}>NEXT</BasicText>
            </BasicButton>
        </InputBoxWrap>
    )
}

const InputBoxWrap = styled.div`
width: 50%;
`

const FTPInputWrap = styled.div`
display: flex;
align-items: center;
margin-bottom: 10px;
`

const InputTitleWrap = styled.div`
width: 40%;
`