import { useState } from "react"
import { useNavigate, useParams } from "react-router"
import styled from "styled-components"
import BasicInput from "../../basic/BasicInput";
import BasicText from "../../basic/BasicText";
import BasicButton from "../../basic/BasicButton";
import { apiDeleteFTPInfo, apiEditFTPInfo, apiGetFTPDetailInfo } from "../../../api/ftp/ftp_api";
import { userUserAuthPayload } from "../../../utils/custom-hooks/common/userUserAuthPayload";
import TitleWithTextFieldItem from "../../common/item/TitleWithTextFieldItem";
import TitleWithSelectItem from "../../common/item/TitleWithSelectItem";

export default function FTPDetailPart() {
    const params: any = useParams();
    const authPayload = userUserAuthPayload();
    const navigate = useNavigate();

    const [ftpCryptoKey, setFTPCryptoKey] = useState<string>('');
    const [ftpInfo, setFTPInfo] = useState<any>();

    function loadFTPDetailHandler() {
        apiGetFTPDetailInfo(params.ftp_id, ftpCryptoKey, authPayload).then((res: any) => {
            if (res.result) {
                setFTPInfo(res.data);
            } else {
                alert(res.error_en);
            }
        })
    }

    function updateFTPInfoHandler(key: string, value: string) {
        setFTPInfo((prev: any) => {
            const newInfo = { ...prev }
            newInfo[key] = value;

            return newInfo;
        })
    }

    function editFTPHandler() {
        apiEditFTPInfo(params.ftp_id, ftpInfo, ftpCryptoKey, authPayload).then((res: any) => {
            console.log(res);
            if (res.result) {
                alert("FTP edited successfully!");
            } else {
                alert(res.error_en);
            }
        })
    }

    function deleteFTPHandler() {
        apiDeleteFTPInfo(params.ftp_id, ftpCryptoKey, authPayload).then((res: any) => {
            if (res.result) {
                alert("FTP deleted successfully!");
                navigate('/ftp');
            } else {
                alert(res.error_en)
            }
        })
    }

    return (
        <PartWrap>
            <PartHeaderWrap>
                <BasicText fontSize={30} fontWeight={700}>EDIT FTP</BasicText>
            </PartHeaderWrap>
            {ftpInfo ? (
                <FTPInfoWrap>
                    <FTPContentWrap>
                        <TitleWithTextFieldItem onChange={(value: string) => { updateFTPInfoHandler('ftp_name', value) }} title="FTP NAME" value={ftpInfo.ftp_name} />
                    </FTPContentWrap>
                    <FTPContentWrap>
                        <TitleWithTextFieldItem onChange={(value: string) => { updateFTPInfoHandler('ftp_host_name', value) }} title="FTP HOSTNAME" value={ftpInfo.ftp_host_name} />
                    </FTPContentWrap>
                    <FTPContentWrap>
                        <TitleWithTextFieldItem onChange={(value: string) => { updateFTPInfoHandler('ftp_domain_name', value) }} title="FTP DOMAIN NAME" value={ftpInfo.ftp_domain_name} />
                    </FTPContentWrap>
                    <FTPContentWrap>
                        <TitleWithSelectItem onChange={(value: string) => { updateFTPInfoHandler('ftp_type', value) }} title="FTP TYPE" value={ftpInfo.ftp_type} selectItems={[
                            { value: '1', title: 'Single Page' },
                            { value: '2', title: 'App(Android)' },
                            { value: '3', title: 'App(IOS)' },
                            { value: '4', title: 'Web' },
                            { value: '5', title: 'Admin' },
                            { value: '6', title: 'API Server' }]}
                        />
                    </FTPContentWrap>
                    <FTPContentWrap>
                        <TitleWithTextFieldItem onChange={(value: string) => { updateFTPInfoHandler('ftp_port', value) }} title="FTP PORT" value={ftpInfo.ftp_port} />
                    </FTPContentWrap>
                    <FTPContentWrap>
                        <TitleWithTextFieldItem onChange={(value: string) => { updateFTPInfoHandler('ftp_remote_port', value) }} title="FTP REMOTE PORT" value={ftpInfo.ftp_remote_port} />
                    </FTPContentWrap>
                    <FTPContentWrap>
                        <TitleWithTextFieldItem onChange={(value: string) => { updateFTPInfoHandler('ftp_user_name', value) }} title="FTP USER NAME" value={ftpInfo.ftp_user_name} />
                    </FTPContentWrap>
                    <FTPContentWrap>
                        <TitleWithTextFieldItem onChange={(value: string) => { updateFTPInfoHandler('ftp_word', value) }} title="FTP PASSWORD" value={ftpInfo.ftp_word} />
                    </FTPContentWrap>
                    <FTPContentWrap style={{ marginBottom: '30px' }}>
                        <TitleWithTextFieldItem onChange={(value: string) => { updateFTPInfoHandler('ftp_description', value) }} title="FTP DESCRIPTION" value={ftpInfo.ftp_description} />
                    </FTPContentWrap>
                    <FTPButtonWrap>
                        <BasicButton onPress={editFTPHandler}>
                            <BasicText color={'white'}>EDIT</BasicText>
                        </BasicButton>
                        <BasicButton color="error" onPress={deleteFTPHandler}>
                            <BasicText color={'white'}>DELETE</BasicText>
                        </BasicButton>
                    </FTPButtonWrap>
                </FTPInfoWrap>
            ) : (
                <CryptoKeyVerifyWrap>
                    <BasicText marginBottom={1}>Please enter your FTP's cryptokey</BasicText>
                    <InputWrap>
                        <BasicInput onValue={(value: string) => setFTPCryptoKey(value)} />
                        <BasicButton sx={{ marginLeft: '10px' }} onPress={loadFTPDetailHandler}>
                            <BasicText color={'white'}>ENTER</BasicText>
                        </BasicButton>
                    </InputWrap>
                </CryptoKeyVerifyWrap>
            )}
        </PartWrap>
    )
}

const PartWrap = styled.div`
padding: 30px;
`

const PartHeaderWrap = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
margin-bottom: 30px;
`

const FTPInfoWrap = styled.div`
width: 50%;
`

const FTPContentWrap = styled.div`
margin-bottom: 10px;
`

const FTPButtonWrap = styled.div`
display: flex;
justify-content: space-between;
`

const CryptoKeyVerifyWrap = styled.div``

const InputWrap = styled.div``