import styled from "@emotion/styled"
import BasicText from "../../basic/BasicText"
import BasicButton from "../../basic/BasicButton"
import { useNavigate } from "react-router"
import ListTableItem from "../../common/item/ListTableItem";
import { useState } from "react";
import BoardListItem from "../item/BoardListItem";

export default function BoardListPart() {
    const navigate = useNavigate();

    const [boardList, setBoardList] = useState([{
        id: 1,
        title: '펭귄 구합니다',
        writer: '핑구',
        timestamp: 123456,
    }]);

    return (
        <PartWrap>
            <PartHeaderWrap>
                <BasicText fontSize={30} fontWeight={700}>BOARD LIST</BasicText>
                <BasicButton onPress={() => { navigate('/board/upload') }}>
                    <BasicText>NEW BOARD</BasicText>
                </BasicButton>
            </PartHeaderWrap>
            <TableWrap>
                <ListTableItem headerData={[
                    { title: '번호', width: '10%' },
                    { title: '제목', width: '30%' },
                    { title: '작성자', width: '10%' },
                    { title: '시간', width: '10%' },
                    { title: '자세히 보기', width: '10%' },
                ]}>
                    <>
                        {boardList.map((board: any) => {
                            return <BoardListItem itemData={board} />
                        })}
                    </>
                </ListTableItem>
            </TableWrap>
        </PartWrap>
    )
}

const PartWrap = styled.div`
padding: 30px;
`

const PartHeaderWrap = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
margin-bottom: 30px;
`

const TableWrap = styled.div`
border: 1px solid black;
width: 100%;
`