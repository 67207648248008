import { useState } from 'react';
import NavigationBarItem from '../../common/item/NavigationBarItem';
import { NAVIGATE_BAR_MENU_ITEMS } from '../../../config/NavigationBarConfig';
import ApiListPart from '../part/ApiListPart';

export default function ApiListPage() {
    const [openBar, setOpenBar] = useState(true);

    return (
        <NavigationBarItem pageLocation={'/project'} menuItems={NAVIGATE_BAR_MENU_ITEMS} openBar={openBar} setOpenBar={setOpenBar}>
            <ApiListPart openBar={openBar} />
        </NavigationBarItem>
    )
}