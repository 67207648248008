import { axiosConfigInstance } from '../config';
import { ErrorHandler } from '../errorHandler';
import { setUserInfo } from '../../store/common/user';
import { HeaderPayloadType } from '../config';

export async function apiRegisterProject(data: any, payload: HeaderPayloadType) {
  const formData: any = new FormData();

  formData.append('project_icon', data.project_icon[0]);

  const formatData = {
    project_name: data.project_name,
    project_description: data.project_description,
    project_type: data.project_type,
  }

  formData.append('params', JSON.stringify(formatData));
  formData.append('method', 'registerProject');

  const res: any = await axiosConfigInstance('/project', payload).post('', formData).catch((err: any) => {
    return {
      result: false,
      error_en: 'Please try again later!'
    }
  })

  return res.data;
}

export async function apiRegisterPlatforms(project_id: number, data: any, payload: HeaderPayloadType) {
  const res: any = await axiosConfigInstance('/project', payload).post('', {
    method: 'registerPlatforms',
    params: {
      project_id,
      project_platforms: data
    }
  }).catch((err: any) => {
    return {
      result: false,
      error_en: 'Please try again later!'
    }
  })

  return res.data;
}

export async function apiRegisterProjectConfig(project_id: number, data: any, payload: HeaderPayloadType) {
  const res: any = await axiosConfigInstance('/project', payload).post('', {
    method: 'registerProjectConfig',
    params: {
      project_id,
      project_baseURL: data.project_baseURL,
      project_api_port: data.project_api_port,
      project_api_host: data.project_api_host,
      project_db_port: data.project_db_port,
      project_db_user: data.project_db_user,
      project_db_name: data.project_db_name,
      project_jwt_key: data.project_jwt_key,
    }
  }).catch((err: any) => {
    return {
      result: false,
      error_en: 'Please try again later!'
    }
  })

  return res.data;
}

export async function apiGetProjectApiList(project_id: any, payload: HeaderPayloadType) {
  const res = await axiosConfigInstance('/project/api', payload).post('', {
    method: 'getProjectApiList',
    params: {
      project_id: project_id
    }
  }).catch((err: any) => {
    return {
      result: false,
      error_en: 'Please try again later!'
    }
  })

  return ErrorHandler(res);
}

// 프로젝트 api 등록하기
export async function apiRegisterProjectApi(project_id: number, data: any, payload: HeaderPayloadType, dispatch?: any) {
  const res = await axiosConfigInstance('/project/api', payload).post('', {
    method: 'registerProjectApi',
    params: {
      project_id,
      ...data,
      api_auto_return: false,
    }
  });
  return ErrorHandler(res);
}

// 프로젝트 api 수정하기
export async function apiEditProjectApi(project_id: number, data: any, payload: HeaderPayloadType, dispatch?: any) {
  const res = await axiosConfigInstance('/project/api', payload).post('', {
    method: 'editProjectApi',
    params: {
      project_id,
      ...data,
      api_auto_return: false,
    }
  });
  return ErrorHandler(res);
}

// 프로젝트 상세정보 가져오기
export async function apiGetApiDetailInfo(api_id: string, payload: HeaderPayloadType) {
  const res = await axiosConfigInstance('/project/api', payload).post('', {
    method: 'getApiDetailInfo',
    params: {
      api_id: api_id,
    }
  }).catch((err: any) => {
    return {
      result: false,
      errer_en: 'Please try again later!'
    }
  });

  return ErrorHandler(res);
}

export async function apiWriteComment(api_id: string, comment_content: string, payload: HeaderPayloadType) {
  const res = await axiosConfigInstance('/project/api', payload).post('', {
    method: 'writeComment',
    params: {
      api_id,
      comment_content,
    }
  }).catch((err: any) => {
    return {
      result: false,
      errer_en: 'Please try again later!'
    }
  });

  return ErrorHandler(res);
}

export async function apiGetCommentList(api_id: string, page: number, count: number, payload: HeaderPayloadType) {
  const res = await axiosConfigInstance('/project/api', payload).post('', {
    method: 'getCommentList',
    params: {
      api_id,
      page,
      count,
    }
  }).catch((err: any) => {
    return {
      result: false,
      errer_en: 'Please try again later!'
    }
  });

  return ErrorHandler(res);
}

export async function apiEditComment(comment_id: string, comment_content: string, payload: HeaderPayloadType) {
  const res = await axiosConfigInstance('/project/api', payload).post('', {
    method: 'editComment',
    params: {
      comment_id,
      comment_content,
    }
  }).catch((err: any) => {
    return {
      result: false,
      errer_en: 'Please try again later!'
    }
  });

  return ErrorHandler(res);
}

export async function apiDeleteComment(comment_id: string, payload: HeaderPayloadType) {
  const res = await axiosConfigInstance('/project/api', payload).post('', {
    method: 'deleteComment',
    params: {
      comment_id,
    }
  }).catch((err: any) => {
    return {
      result: false,
      errer_en: 'Please try again later!'
    }
  });

  return ErrorHandler(res);
}

export async function apiSetApiControlVisible(api_id: string, api_visible: string, payload: HeaderPayloadType, dispatch?: any) {
  const res: any = await axiosConfigInstance('/project/api', payload).post('', {
    method: 'setApiControlVisible',
    params: {
      api_id,
      api_visible,
    }
  }).catch((err) => {
    return {
      result: false,
      error_en: 'Please try again later!'
    }
  });

  return res.data;
}