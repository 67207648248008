import ReactMarkdown from 'react-markdown';
// import remarkGfm from 'remark-gfm';
import styled from 'styled-components';
import {
    CodeMarkDown,
    H1MarkDown,
    H2MarkDown,
    H3MarkDown,
    H4MarkDown,
    TableMarkDown,
    pMarkDown,
    ulMarkDown,
    aMarkDown
} from './TextMarkdown';
// import rehypeRaw from 'rehype-raw';

export default function TextMarkDownResultPart({ result }: any) {

    // console.log('result', result);

    return (
        <Wrap>
            <ReactMarkdown children={result} linkTarget={'_blank'}
                components={{
                    code: CodeMarkDown,
                    h1: H1MarkDown,
                    h2: H2MarkDown,
                    h3: H3MarkDown,
                    h4: H4MarkDown,
                    p: pMarkDown,
                    ul: ulMarkDown,
                    table: TableMarkDown,
                    a: aMarkDown
                }}
            />
        </Wrap>
    );
}

const Wrap = styled.div`
    width: 100%;
    line-height: 1.5rem;
`;