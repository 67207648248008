import { createAction, handleActions } from 'redux-actions';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../store';

const SET_USER_LOGIN = 'subox-dev/common/SET_USER_LOGIN';
const SET_USER_LOGOUT = 'subox-dev/common/SET_USER_LOGOUT';

export const setUserInfoLoginActionCreator = createAction(SET_USER_LOGIN);
export const setUserInfoLogOutActionCreator = createAction(SET_USER_LOGOUT);

export function setUserInfo(data: any): ThunkAction<void, RootState, null, ReturnType<typeof setUserInfoLoginActionCreator>> {
  return async function (dispatch) {
    try {
      dispatch(setUserInfoLoginActionCreator({
        data: data
      }));
    } catch (error) {

    }
  };
}

export interface userInfo {
  user_id: any;
  user_name: string;
  user_email: string;
  user_level: number;
  user_company: string;
  user_position: string;
  user_permission: string;
  access_token: string;
  authorization: string;
}

const initialState: userInfo = {
  user_id: '',
  user_name: '',
  user_email: '',
  user_level: 5,
  user_company: '',
  user_position: '',
  user_permission: '0',
  access_token: '0',
  authorization: '0',
};

export const user = handleActions<userInfo, any>({
  [SET_USER_LOGIN]: (state, action: ReturnType<typeof setUserInfoLoginActionCreator>) => ({
    user_id: action.payload.data.user_id ?? state.user_id,
    user_name: action.payload.data.user_name ?? state.user_name,
    user_email: action.payload.data.user_email ?? state.user_email,
    user_level: action.payload.data.user_level ?? state.user_level,
    user_company: action.payload.data.user_company ?? state.user_company,
    user_position: action.payload.data.user_position ?? state.user_position,
    user_permission: action.payload.data.user_permission ?? state.user_permission,
    access_token: action.payload.data.access_token ?? state.access_token,
    authorization: action.payload.data.authorization ?? state.authorization,
  })
  ,
  [SET_USER_LOGOUT]: (state, action: ReturnType<typeof setUserInfoLogOutActionCreator>) => (initialState),
}, initialState);
