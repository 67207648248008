import { useState } from "react";
import NavigationBarItem from "../../common/item/NavigationBarItem";
import { NAVIGATE_BAR_MENU_ITEMS } from "../../../config/NavigationBarConfig";
import BoardListPart from "../part/BoardListPart";

export default function BoardListPage() {
    const [openBar, setOpenBar] = useState(true);

    return (
        <NavigationBarItem pageLocation={'/board'} menuItems={NAVIGATE_BAR_MENU_ITEMS} openBar={openBar} setOpenBar={setOpenBar}>
            <BoardListPart />
        </NavigationBarItem>
    )
}