import { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import BasicText from '../../basic/BasicText';
import RegisterParamsItem from '../item/RegisterParamsItem';
import { apiEditProjectApi, apiGetApiDetailInfo, apiSetApiControlVisible } from '../../../api/project/project_api_api';
import { useDispatch } from 'react-redux';
import { userUserAuthPayload } from '../../../utils/custom-hooks/common/userUserAuthPayload';
import { useNavigate, useParams } from 'react-router';
import BasicButton from '../../basic/BasicButton';
import TitleWithTextAreaItem from '../../common/item/TitleWithTextAreaItem';
import TitleWithTextFieldItem from '../../common/item/TitleWithTextFieldItem';
import TitleWithSelectItem from '../../common/item/TitleWithSelectItem';
import { Switch } from '@mui/material';

export default function EditApiPart() {
    const dispatch = useDispatch();
    const authPayload = userUserAuthPayload();
    const params: any = useParams();
    const navigate = useNavigate();

    const [apiInfo, setApiInfo] = useState<any>();

    useEffect(() => {
        apiGetApiDetailInfo(params.api_id, authPayload).then(res => {
            if (res.result) {
                res.data.api_return_value = res.data.api_return_value.substring(8, res.data.api_return_value.length)
                setApiInfo(res.data);
            }
        })
    }, []);

    const onSubmitAPI = async () => {
        apiEditProjectApi(params.project_id, apiInfo, authPayload, dispatch).then(response => {
            if (response.result) {
                alert('성공적으로 수정되었습니다.');
            } else {
                alert(`${response.data.error}`);
            }
        })
    }

    const paramsChangeAction = (action: string) => {
        if (action === 'add') {
            setApiInfo((prev: any) => {
                const newInfo = { ...prev };
                newInfo.api_params.push({ params_type: 'string', params_select: '0' })

                return newInfo;
            })
        } else {
            setApiInfo((prev: any) => {
                const newInfo = { ...prev }
                newInfo.api_params.pop();

                return newInfo;
            })
        }
    }

    function updateApiInfoHandler(key: string, value: any, index?: number) {
        setApiInfo((prev: any) => {
            if (index !== undefined) {
                prev.api_params[index][key] = value;

                return {
                    ...prev
                }
            }

            return {
                ...prev,
                [key]: value
            }
        })
    }

    function hideProjectHandler(event: any) {
        apiSetApiControlVisible(params.api_id, event.target.checked ? '0' : '1', authPayload).then((res: any) => {
            if (res.result) {
                if (event.target.checked) alert('API hidden successfully!');
                else alert('API unhidden successfully!');
            }
        })
    }

    return (
        <PartWrap>
            <PartHeaderWrap>
                <BasicText fontSize={30} fontWeight={700}>EDIT API</BasicText>
                <ProjectHideButtonWrap>
                    <BasicText marginRight={1}>PROJECT HIDE</BasicText>
                    <Switch onChange={hideProjectHandler} />
                </ProjectHideButtonWrap>
            </PartHeaderWrap>
            {apiInfo && (
                <UploadInputWrap>
                    <BasicInfoWrap>
                        <TitleWithTextFieldItem value={apiInfo.api_name} title='API NAME' showTextLength={false} onChange={(value: any) => updateApiInfoHandler('api_name', value)} style={{ marginBottom: '15px' }} />
                        <TitleWithTextFieldItem value={apiInfo.api_description} title='API DESCRIPTION' showTextLength={false} onChange={(value: any) => updateApiInfoHandler('api_description', value)} style={{ marginBottom: '15px' }} />
                        <TitleWithTextFieldItem value={apiInfo.api_endpoint} title='API ENDPOINT' showTextLength={false} onChange={(value: any) => updateApiInfoHandler('api_endpoint', value)} style={{ marginBottom: '15px' }} />
                        <TitleWithTextFieldItem value={apiInfo.api_method} title='API METHOD' showTextLength={false} onChange={(value: any) => updateApiInfoHandler('api_method', value)} style={{ marginBottom: '15px' }} />
                        <TitleWithSelectItem title='API USE TYPE' value={apiInfo.api_use_type} onChange={(value: any) => { updateApiInfoHandler('api_use_type', value); }} selectItems={[{ value: '1', title: '클라이언트' }, { value: '2', title: '관리자' }]} style={{ marginBottom: '15px' }} />
                        <TitleWithSelectItem title='API LEVEL' value={apiInfo.api_level} onChange={(value: any) => { updateApiInfoHandler('api_level', value); }} selectItems={[{ value: '1', title: '1' }, { value: '2', title: '2' }, { value: '3', title: '3' }, { value: '4', title: '4' }, { value: '5', title: '5' }]} style={{ marginBottom: '15px' }} />
                        <TitleWithTextAreaItem value={apiInfo.api_return_value} title='API RETURN' showTextLength={false} onChange={(value: any) => updateApiInfoHandler('api_return_value', value)} />
                    </BasicInfoWrap>
                    <ParamsInfoWrap>
                        <InfoHeaderWrap>
                            <BasicText fontSize={22} fontWeight={700}>PARAMS INFO</BasicText>
                            <div>
                                <BasicButton onClick={() => paramsChangeAction('add')} sx={{ marginRight: '10px' }}>
                                    <BasicText>+</BasicText>
                                </BasicButton>
                                <BasicButton onClick={() => paramsChangeAction('remove')}>
                                    <BasicText>-</BasicText>
                                </BasicButton>
                            </div>
                        </InfoHeaderWrap>
                        <ParamsListWrap>
                            {apiInfo.api_params.map((data: any, index: number) => {
                                return (
                                    <RegisterParamsItem key={`params-input-${index}`} data={data} index={index} onParamsValue={updateApiInfoHandler} />
                                )
                            })}
                        </ParamsListWrap>
                    </ParamsInfoWrap>
                    <InputButtonWrap>
                        <BasicButton onClick={() => { navigate(`/project/${params.project_id}/apis`) }}>
                            <BasicText>BACK</BasicText>
                        </BasicButton>
                        <BasicButton onClick={onSubmitAPI}>
                            <BasicText>EDIT</BasicText>
                        </BasicButton>
                    </InputButtonWrap>
                </UploadInputWrap>
            )}
        </PartWrap>
    )
}

const PartWrap = styled.div`
padding: 30px;
width: 100%;
`;

const PartHeaderWrap = styled.div`
margin-bottom: 30px;
display: flex;
align-items: center;
justify-content: space-between;
`

const ProjectHideButtonWrap = styled.div`
display: flex;
align-items: center;
`

const UploadInputWrap = styled.div`
width: 50%;
`

const BasicInfoWrap = styled.div`
margin-bottom: 30px;
`

const ParamsInfoWrap = styled.div`
border-radius: 10px;
padding: 30px;
margin-bottom: 30px;
box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
`

const InfoHeaderWrap = styled.div`
display: flex;
justify-content: space-between;
margin-bottom: 15px;
`

const ParamsListWrap = styled.div``

const InputButtonWrap = styled.div`
display: flex;
justify-content: space-between;
`