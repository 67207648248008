import { useState } from "react";
import { NAVIGATE_BAR_MENU_ITEMS } from "../../../config/NavigationBarConfig";
import NavigationBarItem from "../../common/item/NavigationBarItem";
import BoardUploadPart from "../part/BoardUploadPart";

export default function BoardUploadPage() {
    const [openBar, setOpenBar] = useState(true);

    return (
        <NavigationBarItem pageLocation={'/board'} menuItems={NAVIGATE_BAR_MENU_ITEMS} openBar={openBar} setOpenBar={setOpenBar}>
            <BoardUploadPart />
        </NavigationBarItem>
    )
}