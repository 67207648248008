import BasicButton from "../../basic/BasicButton";
import BasicText from "../../basic/BasicText";
import styled from "styled-components";
import TitleWithTextFieldItem from "../../common/item/TitleWithTextFieldItem";

export default function ServerInfoPart({ info, onInputChange, setUploadPage, onSubmit }: any) {
    return (
        <InputBoxWrap>
            <ServerInputWrap>
                <InputTitleWrap>
                    <BasicText>BASIC URL :{'\u00A0'}</BasicText>
                </InputTitleWrap>
                <TitleWithTextFieldItem value={info.project_baseURL} style={{ flexGrow: 1 }} onChange={(value: string) => { onInputChange('project_baseURL', value) }} />
            </ServerInputWrap>
            <ServerInputWrap>
                <InputTitleWrap>
                    <BasicText>PORT NUMBER :{'\u00A0'}</BasicText>
                </InputTitleWrap>
                <TitleWithTextFieldItem value={info.project_api_port} style={{ flexGrow: 1 }} onChange={(value: string) => { onInputChange('project_api_port', value) }} />
            </ServerInputWrap>
            <ServerInputWrap>
                <InputTitleWrap>
                    <BasicText>HOST :{'\u00A0'}</BasicText>
                </InputTitleWrap>
                <TitleWithTextFieldItem value={info.project_api_host} style={{ flexGrow: 1 }} onChange={(value: string) => { onInputChange('project_api_host', value) }} />
            </ServerInputWrap>
            <ServerInputWrap>
                <InputTitleWrap>
                    <BasicText>DB PORT :{'\u00A0'}</BasicText>
                </InputTitleWrap>
                <TitleWithTextFieldItem value={info.project_db_port} style={{ flexGrow: 1 }} onChange={(value: string) => { onInputChange('project_db_port', value) }} />
            </ServerInputWrap>
            <ServerInputWrap>
                <InputTitleWrap>
                    <BasicText>DB USER :{'\u00A0'}</BasicText>
                </InputTitleWrap>
                <TitleWithTextFieldItem value={info.project_db_user} style={{ flexGrow: 1 }} onChange={(value: string) => { onInputChange('project_db_user', value) }} />
            </ServerInputWrap>
            <ServerInputWrap>
                <InputTitleWrap>
                    <BasicText>DB NAME :{'\u00A0'}</BasicText>
                </InputTitleWrap>
                <TitleWithTextFieldItem value={info.project_db_name} style={{ flexGrow: 1 }} onChange={(value: string) => { onInputChange('project_db_name', value) }} />
            </ServerInputWrap>
            <ServerInputWrap>
                <InputTitleWrap>
                    <BasicText>JWT SECRET KEY :{'\u00A0'}</BasicText>
                </InputTitleWrap>
                <TitleWithTextFieldItem value={info.project_jwt_key} style={{ flexGrow: 1 }} onChange={(value: string) => { onInputChange('project_jwt_key', value) }} />
            </ServerInputWrap>
            <InputMoveWrap>
                <BasicButton onPress={() => { setUploadPage(2) }}>
                    <BasicText color={'white'}>BEFORE</BasicText>
                </BasicButton>
                <BasicButton onPress={() => { onSubmit() }}>
                    <BasicText color={'white'}>SUBMIT</BasicText>
                </BasicButton>
            </InputMoveWrap>
        </InputBoxWrap>
    )
}

const InputBoxWrap = styled.div`
width: 50%;
`

const ServerInputWrap = styled.div`
display: flex;
align-items: center;
margin-bottom: 10px;
`

const InputTitleWrap = styled.div`
width: 40%;
`

const InputMoveWrap = styled.div`
display: flex;
justify-content: space-between;
`