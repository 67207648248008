import { useState } from 'react';
import { useParams } from 'react-router';
import RegisterApiPart from '../part/RegisterApiPart';
import NavigationBarItem from '../../common/item/NavigationBarItem';
import { NAVIGATE_BAR_MENU_ITEMS } from '../../../config/NavigationBarConfig';

export default function RegisterApiPage() {
    const { project_id } = useParams();

    const [openBar, setOpenBar] = useState(true);

    return (
        <NavigationBarItem pageLocation={'/project'} menuItems={NAVIGATE_BAR_MENU_ITEMS} openBar={openBar} setOpenBar={setOpenBar}>
            <RegisterApiPart project_id={project_id} />
        </NavigationBarItem>
    )
}
