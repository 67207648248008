import { HeaderPayloadType, axiosConfigInstance } from "../config";
import { ErrorHandler } from "../errorHandler";

export async function apiGetCommonProtocolCodeList(page: number, count: number, payload: HeaderPayloadType, dispatch?: any) {
    const res = await axiosConfigInstance('/code', payload).post('', {
        method: 'getCommonProtocolCodeList',
        params: {
            page,
            count
        }
    });
    return ErrorHandler(res);
}

export async function apiEditCommonCode(data: any, payload: HeaderPayloadType, dispatch?: any) {
    const res = await axiosConfigInstance('/code', payload).post('', {
        method: 'editCommonCode',
        params: {
            code_id: data.code_id.toString(),
            code_name: data.code_name,
            code_type: data.code_type,
            code_number: data.code_number,
            code_description: data.code_description,
            code_error_text_kr: data.code_error_text_kr,
            code_error_text_en: data.code_error_text_en,
        }
    });

    return res.data;
}

export async function apiAddCommonCode(data: any, payload: HeaderPayloadType, dispatch?: any) {
    const res: any = await axiosConfigInstance('/code', payload).post('', {
        method: 'addCommonCode',
        params: {
            ...data
        }
    }).catch(() => {
        return {
            result: false,
            error_en: 'Please try again later!'
        }
    });

    return res.data;
}