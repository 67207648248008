export const dummy_api_list = [
    // {
    //     api_id: 1,
    //     api_name: 'stat success today',
    //     api_endpoint: '/stat/success/rate',
    //     api_description: 'stat success today value',
    //     api_level: 2,
    //     api_linkDB: 'pick_data_alarm',
    //     api_timestamp: 1659344500000,
    //     api_platform: 'Signal Check',
    //     api_baseURL: 'https://trading.subox.co.kr',
    //     api_status: 200,
    //     api_params: [
    //         {
    //             params_id: '1',
    //             params_key: 'params1',
    //             params_value: '1',
    //             params_type: 'string',
    //             params_selected: '1'
    //         },
    //         {
    //             params_id: '2',
    //             params_key: 'params2',
    //             params_value: '2',
    //             params_type: 'string',
    //             params_selected: '1'
    //         },
    //     ]
    // },
]