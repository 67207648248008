import styled from "@emotion/styled"
import BasicButton from "../../basic/BasicButton"
import BasicText from "../../basic/BasicText"
import { useNavigate } from "react-router"
import { useUserInfo } from "../../../utils/custom-hooks/common/useUserInfo";
import { useDispatch } from "react-redux";
import { setUserInfoLogOutActionCreator } from "../../../store/common/user";
import { useEffect } from "react";

export default function HomePage() {
    const navigate = useNavigate();
    const userInfo = useUserInfo();
    const dispatch = useDispatch();

    useEffect(()=>{
        if(userInfo.user_id === ''){
            navigate('/login');
        }else{
            navigate('/project')
        }
    },[userInfo.user_id])

    function userLogOutHandler() {
        dispatch(setUserInfoLogOutActionCreator());
    }

    return (
        <PageWrap>
            {userInfo.user_id === '' ? (
                <BasicButton onPress={() => { navigate('/login') }}>
                    <BasicText>LOGIN</BasicText>
                </BasicButton>
            ) : (
                <HomeButtonWrap>
                    <BasicButton onPress={() => { navigate('/project') }} sx={{ marginBottom: '10px' }}>
                        <BasicText>GO TO THE PROJECT LIST</BasicText>
                    </BasicButton>
                    <BasicButton color="secondary" onPress={userLogOutHandler}>
                        <BasicText>LOGOUT</BasicText>
                    </BasicButton>
                </HomeButtonWrap>
            )}
        </PageWrap>
    )
}

const PageWrap = styled.div`
background: white;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
height: 100vh;
`

const HomeButtonWrap = styled.div`
display: flex;
flex-direction: column;
align-items: center;
`